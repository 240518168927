import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import { GenericMasterData, OrganizationDetail } from "../../../libs/model-lib";
import {
  addressListState,
  numberOfEmployeeListState,
  numberOfStaffBedListState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { getErrorDetail } from "../../../libs/common-lib";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import { getAddressValue } from "../../../libs/services-lib";
import { useOrganizationDetailContext } from "../../../libs/context-lib/organizationDetailContext";

interface IOrganizationDetailForm {
  errorMessage: any[];
}

export const OrganizationDetailForm = (props: IOrganizationDetailForm) => {
  const [numberOfEmployeeList, setNumberOfEmployeeList] = useState<
    GenericMasterData[]
  >(numberOfEmployeeListState.getState().numberOfEmployeeList);
  const [numberOfStaffBedList, setNumberOfStaffBedList] = useState<
    GenericMasterData[]
  >(numberOfStaffBedListState.getState().numberOfStaffBedList);
  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const { organizationDetail, setOrganizationDetail } =
    useOrganizationDetailContext();

  useLayoutEffect(() => {
    const numberOfEmployeeListStateSub = numberOfEmployeeListState.subscribe(
      async (state) => {
        setNumberOfEmployeeList(state.numberOfEmployeeList);
      }
    );

    const numberOfStaffBedListStateSub = numberOfStaffBedListState.subscribe(
      async (state) => {
        setNumberOfStaffBedList(state.numberOfStaffBedList);
      }
    );

    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    return () => {
      numberOfEmployeeListStateSub();
      numberOfStaffBedListStateSub();
      addressListStateSub();
    };
  }, []);

  const handleNPIChange = (event: any) => {
    const inputValue = event.target.value;
    const npiValue = inputValue.replace(/\D/g, "");
    if (npiValue.length <= 10) {
      setOrganizationDetail({
        ...organizationDetail,
        npi: npiValue,
      });
    }
  };

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const postalCode = inputValue.replace(/\D/g, "");
    if (postalCode.length <= 5) {
      setOrganizationDetail({
        ...organizationDetail,
        postal_code: postalCode,
      });
    }
  };

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (event.target.value.length >= 3) {
      await getAddressValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setOrganizationDetail({
        ...organizationDetail,
        address_1: `${!!value.streetNumber ? value.streetNumber : ""} ${
          !!value.streetName ? value.streetName : ""
        }`,
        address_2: "",
        city: `${!!value.municipality ? value.municipality : ""}`,
        state: `${!!value.countrySubdivision ? value.countrySubdivision : ""}`,
        postal_code: `${!!value.postalCode ? value.postalCode : ""}`,
      });
    }
  };

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600
              text="Organization contact details"
              color="#17191C"
            />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter name"}
                  value={organizationDetail.name}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Phone number" color="#737F8C" />
                <PhoneNumberTextField
                  placeholder="Enter phone number"
                  value={organizationDetail.primary_phone_number}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      primary_phone_number: e,
                    });
                  }}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="NPI" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter NPI"}
                  value={organizationDetail.npi}
                  handleChange={handleNPIChange}
                  errorMessage={getErrorDetail("npi", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Website" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter website"}
                  value={organizationDetail.website}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      website: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("website", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Address" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Home address 1"
                  color="#737F8C"
                  required
                />
                <AutoCompleteTextInput
                  value={organizationDetail.address_1}
                  onChange={handleAutocompleteChange}
                  onInputChange={(event: any, newInputValue: any) => {
                    handleAddressAutopopulate(event, newInputValue);
                    setOrganizationDetail({
                      ...organizationDetail,
                      address_1: event.target.value,
                    });
                  }}
                  options={addressValue}
                  getOptionLabel={(option: any) =>
                    typeof option === "string" ? option : option.freeformAddress
                  }
                  filterOptions={(x: any) => x}
                  noOptionsText="No locations"
                  label={""}
                  placeholder={"Enter home address 1"}
                  errorMessage={getErrorDetail("address_1", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Home address 2" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter home address 2"}
                  value={organizationDetail.address_2}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      address_2: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("address_2", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="City" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter city"}
                  value={organizationDetail.city}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      city: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("city", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="State" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter state"}
                  value={organizationDetail.state}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      state: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("state", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Postal code" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter postal code"}
                  value={organizationDetail.postal_code}
                  handleChange={handlePostalcodeChange}
                  errorMessage={getErrorDetail(
                    "postal_code",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
            {/* <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Country" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter country"}
                  value={""}
                  handleChange={() => {}}
                />
              </LableTextFieldBox>
            </Box> */}
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600
              text="Amount of healthcare providers"
              color="#17191C"
              required
            />
          </Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {numberOfEmployeeList.map((cc) => (
              <FormControlLabel
                sx={{
                  alignContent: "center",
                  gap: "8px",
                }}
                value={cc.id}
                key={cc.id}
                checked={organizationDetail.number_of_employee == cc.id}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "#0D80F2",
                      "&.Mui-checked": {
                        color: "#0D80F2",
                      },
                      "&:hover": {
                        color: "#0A66C2",
                      },
                    }}
                  />
                }
                label={<Typography16_400 text={cc.name} color="#17191C" />}
                onChange={(e: any) => {
                  setOrganizationDetail({
                    ...organizationDetail,
                    number_of_employee: parseInt(e.target.value),
                  });
                }}
              />
            ))}
          </RadioGroup>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Number of beds" color="#17191C" required />
          </Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {numberOfStaffBedList.map((cc) => (
              <FormControlLabel
                sx={{
                  alignContent: "center",
                  gap: "8px",
                }}
                value={cc.id}
                key={cc.id}
                checked={organizationDetail.number_of_staffed_beds == cc.id}
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "#0D80F2",
                      "&.Mui-checked": {
                        color: "#0D80F2",
                      },
                      "&:hover": {
                        color: "#0A66C2",
                      },
                    }}
                  />
                }
                label={<Typography16_400 text={cc.name} color="#17191C" />}
                onChange={(e: any) => {
                  setOrganizationDetail({
                    ...organizationDetail,
                    number_of_staffed_beds: parseInt(e.target.value),
                  });
                }}
              />
            ))}
          </RadioGroup>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};

import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  CalendarMonthOutlinedIcon,
  DropdownWithBorder,
  FormContainerBox,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useLayoutEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  addressListState,
  agencyDetailListState,
  agencyMappedDetailListState,
  organizationDetailState,
  staffTypeListState,
} from "../../../libs/state-management-lib";
import { GenericMasterData, StaffDetail } from "../../../libs/model-lib";
import { staffDetailState } from "../../../libs/state-management-lib/staffState";
import dayjs, { Dayjs } from "dayjs";
import {
  EmptyGuid,
  emptyStaffDetail,
  getErrorDetail,
} from "../../../libs/common-lib";
import { useShallow } from "zustand/react/shallow";
import { format } from "date-fns";
import { getAddressValue, getMappedAgency } from "../../../libs/services-lib";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import { useStaffDetailContext } from "../../../libs/context-lib/staffDetailContext";

interface IStaffDetailForm {
  errorMessage: any[];
}

export const StaffDetailForm = (props: IStaffDetailForm) => {
  const [staffTypeList, setStaffTypeList] = useState<GenericMasterData[]>(
    staffTypeListState.getState().staffTypeList
  );
  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const [agencyDetailList, setAgencyDetailList] = useState<any[]>([]);
  const { staffDetail, setStaffDetail } = useStaffDetailContext();

  useLayoutEffect(() => {
    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    const agencyMappedDetailListStateSub =
      agencyMappedDetailListState.subscribe(async (state) => {
        setAgencyDetailList(state.agencyMappedDetailList);
      });

    return () => {
      addressListStateSub();
      agencyMappedDetailListStateSub();
    };
  }, []);

  useEffect(() => {
    (async function () {
      await getMappedAgency(organizationDetailState.getState().id);
    })();
  }, []);

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const zipCode = inputValue.replace(/\D/g, "");
    if (zipCode.length <= 5) {
      setStaffDetail({
        ...staffDetail,
        zipcode: zipCode,
      });
    }
  };

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (event.target.value.length >= 3) {
      await getAddressValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setStaffDetail({
        ...staffDetail,
        address_1: `${!!value.streetNumber ? value.streetNumber : ""} ${
          !!value.streetName ? value.streetName : ""
        }`,
        address_2: "",
        city: `${!!value.municipality ? value.municipality : ""}`,
        state: `${!!value.countrySubdivision ? value.countrySubdivision : ""}`,
        zipcode: `${!!value.postalCode ? value.postalCode : ""}`,
      });
    }
  };

  return (
    <>
      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="Talent type" color="#17191C" required />
        </Box>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {staffTypeList.map((cc) => (
            <FormControlLabel
              sx={{
                alignContent: "center",
                gap: "8px",
              }}
              value={cc.id}
              key={cc.id}
              checked={staffDetail.staff_type == cc.id}
              control={
                <Radio
                  size="medium"
                  sx={{
                    color: "#0D80F2",
                    "&.Mui-checked": {
                      color: "#0D80F2",
                    },
                    "&:hover": {
                      color: "#0A66C2",
                    },
                  }}
                />
              }
              label={<Typography16_400 text={cc.name} color="#17191C" />}
              onChange={(e: any) => {
                setStaffDetail({
                  ...staffDetail,
                  staff_type: parseInt(e.target.value),
                  agency_id: EmptyGuid,
                });
              }}
            />
          ))}
        </RadioGroup>
      </FormContainerBox>
      {staffDetail.staff_type == 103 && (
        <FormContainerBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Agency name" color="#737F8C" required />
                <DropdownWithBorder
                  value={staffDetail.agency_id}
                  handleChange={(e: any) => {
                    setStaffDetail({
                      ...staffDetail,
                      agency_id: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "department_type",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={EmptyGuid}>Select agency</MenuItem>

                  {agencyDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      )}

      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="Talent identity" color="#17191C" />
        </Box>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="First name" color="#737F8C" required />
              <PlatformTextField
                placeholder={"Enter first name"}
                value={staffDetail.first_name}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    first_name: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("first_name", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Last name" color="#737F8C" required />
              <PlatformTextField
                placeholder={"Enter last name"}
                value={staffDetail.last_name}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    last_name: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("last_name", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600
                text="Phone number"
                color="#737F8C"
                required={staffDetail.staff_type == 102 ? true : false}
              />
              <PhoneNumberTextField
                placeholder="Enter phone number"
                value={staffDetail.mobile}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    mobile: e,
                  });
                }}
                errorMessage={getErrorDetail("mobile", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600
                text="Email"
                color="#737F8C"
                required={staffDetail.staff_type == 102 ? true : false}
              />
              <PlatformTextField
                placeholder={"Enter email"}
                value={staffDetail.email}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    email: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("email", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Date of birth" color="#737F8C" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture={true}
                  slots={{
                    openPickerIcon: CalendarMonthOutlinedIcon,
                  }}
                  sx={{
                    justifyContent: "center",
                    background: "#F5FAFE",
                    width: "100%",
                    height: "40px !important",
                    borderRadius: "8px",
                    ".MuiInputBase-input": {
                      padding: "8.5px 14px !important",
                      color: "#031A30  !important",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #0D80F2 !important",
                      borderRadius: "8px",
                      outline: "0",
                    },
                    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #0D80F2 !important",
                      borderRadius: "8px",
                    },

                    "& .MuiInputLabel-root": { display: "none" },
                    "& .MuiIconButton-root": {
                      color: "#0D80F2 !important",
                    },
                    Input: {
                      "&::placeholder": {
                        color: "#6EB2F7",
                        opacity: "5",
                      },
                    },
                  }}
                  format="MM-DD-YYYY"
                  value={
                    !!staffDetail.date_of_birth
                      ? dayjs(staffDetail.date_of_birth)
                      : null
                  }
                  onChange={(newValue: any) => {
                    setStaffDetail({
                      ...staffDetail,
                      date_of_birth: format(newValue.toDate(), "MM-dd-yyyy"),
                    });
                  }}
                  slotProps={{
                    textField: {
                      inputProps: {
                        readOnly: true,
                      },
                      placeholder: "Date of birth",
                    },
                  }}
                />
              </LocalizationProvider>
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
      </FormContainerBox>
      <FormContainerBox>
        <Box sx={{ width: "100%" }}>
          <Typography18_600 text="Address" color="#17191C" />
        </Box>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Address 1" color="#737F8C" required />
              <AutoCompleteTextInput
                value={staffDetail.address_1}
                onChange={handleAutocompleteChange}
                onInputChange={(event: any, newInputValue: any) => {
                  handleAddressAutopopulate(event, newInputValue);
                  setStaffDetail({
                    ...staffDetail,
                    address_1: event.target.value,
                  });
                }}
                options={addressValue}
                getOptionLabel={(option: any) =>
                  typeof option === "string" ? option : option.freeformAddress
                }
                filterOptions={(x: any) => x}
                noOptionsText="No locations"
                label={""}
                placeholder={"Enter address 1"}
                errorMessage={getErrorDetail("address_1", props.errorMessage)}
                freeSolo={true}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Address 2" color="#737F8C" />
              <PlatformTextField
                placeholder={"Enter address 2"}
                value={staffDetail.address_2}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    address_2: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("address_2", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
        <TextFieldPlacementBox>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="City" color="#737F8C" />
              <PlatformTextField
                placeholder={"Enter city"}
                value={staffDetail.city}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    city: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("city", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "25%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="State" color="#737F8C" />
              <PlatformTextField
                placeholder={"Enter state"}
                value={staffDetail.state}
                handleChange={(e: any) => {
                  setStaffDetail({
                    ...staffDetail,
                    state: e.target.value,
                  });
                }}
                errorMessage={getErrorDetail("state", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "25%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Postal code " color="#737F8C" />
              <PlatformTextField
                placeholder={"e.g 30305"}
                value={!!staffDetail.zipcode ? staffDetail.zipcode : ""}
                handleChange={handlePostalcodeChange}
                errorMessage={getErrorDetail("zipcode", props.errorMessage)}
              />
            </LableTextFieldBox>
          </Box>
        </TextFieldPlacementBox>
      </FormContainerBox>
    </>
  );
};

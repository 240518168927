import { Box, SxProps, Theme } from "@mui/material";
import { OverFlowBox, SettingBox, Typography18_600 } from "../../atom";
import SettingsFooterBox from "../../atom/box/settingsFooterBox";
import SettingsPlayBox from "../../atom/box/settingsPlayBox";
import { SettingNavigation } from "../../molecule";
import { ReactNode } from "react";

interface IDetailSettingBox {
  navigationSelectedMenu: string;
  playBoxTitle: string;
  playBoxDuration: string;
  children: ReactNode;
  pageTitle: string;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  sx?:SxProps<Theme>;
}

export const DetailSettingBox = (props: IDetailSettingBox) => {
  return (
    <Box
      sx={{
        ...props.sx,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SettingNavigation selectedMenu={props.navigationSelectedMenu} />
      <OverFlowBox
        sx={{
          width: "85%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <SettingsPlayBox
          text={props.playBoxTitle}
          duration={props.playBoxDuration}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",
            borderBottom: "1px solid rgb(207, 229, 252)"
          }}
        >
          <Typography18_600 sx={{paddingLeft:"1%"}} text={props.pageTitle} color="#17191C" />
        </Box>
        <SettingBox>{props.children}</SettingBox>
        <SettingsFooterBox
          handleCancelClick={props.handleCancelClick}
          handleSaveClick={props.handleSaveClick}
        />
      </OverFlowBox>
    </Box>
  );
};

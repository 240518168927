import { Box, Checkbox } from "@mui/material";
import {
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import { LocationDetail } from "../../../libs/model-lib";
import { EmptyLocationDetail, getErrorDetail } from "../../../libs/common-lib";
import {
  locationClearedState,
  locationDetailState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { useLocationDetailContext } from "../../../libs/context-lib/locationDetailContext";

interface ILocationContactInfoForm {
  errorMessage: any[];
}
export const LocationContactInfoForm = (props: ILocationContactInfoForm) => {
  const { locationDetail, setLocationDetail } = useLocationDetailContext();
  const [sameContactAsOrg, setSameContactAsOrg] = useState(false);

  useLayoutEffect(() => {
    const locationClearedStateSub = locationClearedState.subscribe(
      async (state) => {
        setSameContactAsOrg(false);
      }
    );

    return () => {
      locationClearedStateSub();
    };
  }, []);

  const handleSameAsOrgChecked = (e: any) => {
    if (e.target.checked) {
      let organizationDetail = organizationDetailState.getState();
      setLocationDetail((prevState) => ({
        ...prevState,
        locationContactDetails: {
          ...prevState.locationContactDetails,
          name: organizationDetail.contact_name,
          email: organizationDetail.contact_email,
          title: organizationDetail.contact_title,
          phone_number: organizationDetail.contact_phone_number,
        },
      }));
      setSameContactAsOrg(true);
    } else if (!e.target.checked) {
      setLocationDetail((prevState) => ({
        ...prevState,
        locationContactDetails: {
          ...prevState.locationContactDetails,
          name: "",
          email: "",
          title: "",
          phone_number: "",
        },
      }));
      setSameContactAsOrg(false);
    }
  };
  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Main location contact" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              checked={sameContactAsOrg}
              onChange={handleSameAsOrgChecked}
            />
            <Typography16_400 text="Same as organization" color="#17191C" />
          </Box>

          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter name"}
                  value={locationDetail.locationContactDetails.name}
                  handleChange={(e: any) => {
                    setLocationDetail((prevState) => ({
                      ...prevState,
                      locationContactDetails: {
                        ...prevState.locationContactDetails,
                        name: e.target.value,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail("locationContactDetails$name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Title" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter title"}
                  value={locationDetail.locationContactDetails.title}
                  handleChange={(e: any) => {
                    setLocationDetail((prevState) => ({
                      ...prevState,
                      locationContactDetails: {
                        ...prevState.locationContactDetails,
                        title: e.target.value,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail("locationContactDetails$title", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Phone number" color="#737F8C" />
                <PhoneNumberTextField
                  placeholder="Enter phone number"
                  value={locationDetail.locationContactDetails.phone_number}
                  handleChange={(e: any) => {
                    setLocationDetail((prevState) => ({
                      ...prevState,
                      locationContactDetails: {
                        ...prevState.locationContactDetails,
                        phone_number: e,
                      },
                    }));
                  }}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Email" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter email"}
                  value={locationDetail.locationContactDetails.email}
                  handleChange={(e: any) => {
                    setLocationDetail((prevState) => ({
                      ...prevState,
                      locationContactDetails: {
                        ...prevState.locationContactDetails,
                        email: e.target.value,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail(
                    "locationContactDetails$email",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};

import { Box, SxProps, Theme } from "@mui/material";
import { Typography14_600 } from "../typography";
import { ReactNode } from "react";

interface ISettingNavigationBox {
  navigationText: string;
  active: boolean;
  children?: ReactNode;
  handleNavigationClick: (event: any) => void;
  sx?: SxProps<Theme>;
}

export const SettingNavigationBox = (props: ISettingNavigationBox) => {
  return (
    <Box
      sx={{
        ...props.sx,
        width: "calc(100% - 32px)",
        padding: "12px 16px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        // height: "5%",
        gap: "4px",
        P: {
          color: props.active ? "#ffffff" : "",
        },
        SVG: {
          color: props.active ? "#ffffff" : "",
        },
        "&:hover": {
          cursor: "pointer",
          background: (theme) => theme.palette.primary.contrastText,
        },
        background: props.active
          ? (theme) => theme.palette.primary.contrastText
          : "inherit",
        "&:hover P": {
          color: "#ffffff",
        },
        "&:hover svg": {
          color: "#ffffff",
        },
      }}
      onClick={props.handleNavigationClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {props.children}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography14_600
          text={props.navigationText}
          sx={{ lineHeight: "22.4px" }}
        />
      </Box>
    </Box>
  );
};

export const SettingNavigationBoxWithoutHover = (props: ISettingNavigationBox) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 32px)",
        padding: "12px 16px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        // height: "5%",
        gap: "4px",
        P: {
          color: props.active ? "#ffffff" : "",
        },
        SVG: {
          color: props.active ? "#ffffff" : "",
        },
        background: props.active
          ? (theme) => theme.palette.primary.contrastText
          : "inherit",
      }}
      onClick={props.handleNavigationClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {props.children}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography14_600
          text={props.navigationText}
          sx={{ lineHeight: "22.4px" }}
        />
      </Box>
    </Box>
  );
};

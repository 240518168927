import {
  Box,
  Chip,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  CalendarTodayOutlinedIcon,
  LableTextFieldBox,
  OverFlowBox,
} from "../../atom";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import {
  GenericMasterData,
  ShiftSeries,
  ShiftSeriesDay,
  ShiftSeriesDayForm,
  ShiftSeriesTimeForm,
} from "../../../libs/model-lib";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { grey } from "@mui/material/colors";
import { EmptyGuid, emptyShiftSeries } from "../../../libs/common-lib";
import {
  recurrencePeriodListState,
  shiftSeriesState,
} from "../../../libs/state-management-lib";
import { format } from "date-fns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { CalendarSeriesShiftDayTime } from "./calendarSeriesShiftDayTime";
import { useShiftSeriesContext } from "../../../libs/context-lib/shiftDetailContext";

export const CalendarSeriesShiftDateForm = () => {
  const { shiftSeries, setShiftSeries } = useShiftSeriesContext();
  const [shiftSeriesDayForm, setShiftSeriesDayForm] = useState<ShiftSeriesDayForm[]>([]);
  const [dayList, setDayList] = useState<string[]>([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);

  useEffect(() => {
    let ShiftSeriesDay: ShiftSeriesDay[] = [];
    shiftSeriesDayForm.forEach((field: ShiftSeriesDayForm) => {
      if (!!field.shiftSeriesTimeForm && field.shiftSeriesTimeForm.length > 0) {
        field.shiftSeriesTimeForm.forEach((innerField: ShiftSeriesTimeForm) => {
          if (!!innerField.shiftStartTime && !!innerField.shiftEndTime) {
            if (
              !!ShiftSeriesDay.find(
                (ssd: ShiftSeriesDay) => ssd.day == field.dayNumber
              )
            ) {
              let index = ShiftSeriesDay.findIndex(
                (cc) => cc.day == field.dayNumber
              );
              ShiftSeriesDay[index].shift_times.push({
                shift_series_day_id: EmptyGuid,
                start_time: format(
                  innerField.shiftStartTime?.toDate(),
                  "HH:mm"
                ),
                end_time: format(innerField.shiftEndTime?.toDate(), "HH:mm"),
              });
            } else {
              ShiftSeriesDay.push({
                day: field.dayNumber,
                shift_times: [
                  {
                    shift_series_day_id: EmptyGuid,
                    start_time: format(
                      innerField.shiftStartTime?.toDate(),
                      "HH:mm"
                    ),
                    end_time: format(
                      innerField.shiftEndTime?.toDate(),
                      "HH:mm"
                    ),
                  },
                ],
              });
            }
          }
        });
      }
    });

    let newShiftSeries = {...shiftSeries};
    newShiftSeries.shiftSeriesDays = ShiftSeriesDay;
    setShiftSeries(newShiftSeries);
  }, [shiftSeriesDayForm]);

  const handleToggleClick = (day: string, e: any) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();

    if (e.target.checked) {
      shiftSeriesDetail.push({
        dayNumber: getDayNumber(day),
        shiftSeriesTimeForm: [{ shiftStartTime: null, shiftEndTime: null }],
      });
    } else {
      shiftSeriesDetail = shiftSeriesDetail.filter(
        (dd: ShiftSeriesDayForm) => dd.dayNumber != getDayNumber(day)
      );
    }

    setShiftSeriesDayForm(shiftSeriesDetail);
  };

  const handleSeriesStartTimeChange = (
    dayNumber: number,
    startTimeValue: Dayjs | null,
    timeIndex: number
  ) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();
    let index = shiftSeriesDetail.findIndex((cc) => cc.dayNumber == dayNumber);
    shiftSeriesDetail[index].shiftSeriesTimeForm[timeIndex].shiftStartTime = startTimeValue;
    setShiftSeriesDayForm(shiftSeriesDetail);
  };

  const handleSeriesEndTimeChange = (
    dayNumber: number,
    endTimeValue: Dayjs | null,
    timeIndex: number
  ) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();
    let index = shiftSeriesDetail.findIndex((cc) => cc.dayNumber == dayNumber);
    shiftSeriesDetail[index].shiftSeriesTimeForm[timeIndex].shiftEndTime =
      endTimeValue;
    setShiftSeriesDayForm(shiftSeriesDetail);
  };

  const handleAddSeriesClick = (dayNumber: number) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();
    let index = shiftSeriesDetail.findIndex((cc) => cc.dayNumber == dayNumber);
    if (shiftSeriesDetail[index].shiftSeriesTimeForm.length <= 1) {
      shiftSeriesDetail[index].shiftSeriesTimeForm.push({
        shiftStartTime: null,
        shiftEndTime: null,
      });
    }

    setShiftSeriesDayForm(shiftSeriesDetail);
  }

  const handleRemoveSeriesClick = (dayNumber: number) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();
    let index = shiftSeriesDetail.findIndex((cc) => cc.dayNumber == dayNumber);
    shiftSeriesDetail[index].shiftSeriesTimeForm.pop();
    setShiftSeriesDayForm(shiftSeriesDetail);
  }

  const handleCopySeriesClick = (dayNumber: number) => {
    let shiftSeriesDetail = shiftSeriesDayForm.slice();
    let index = shiftSeriesDetail.findIndex((cc) => cc.dayNumber == dayNumber);

    if (shiftSeriesDetail[index].shiftSeriesTimeForm.length <= 1) {
      shiftSeriesDetail[index].shiftSeriesTimeForm.push({
        shiftStartTime: shiftSeriesDetail[index].shiftSeriesTimeForm[0].shiftStartTime,
        shiftEndTime: shiftSeriesDetail[index].shiftSeriesTimeForm[0].shiftEndTime,
      });
    }

    setShiftSeriesDayForm(shiftSeriesDetail);
  }


  const getDayNumber = (day: string) => {
    let number = 0;

    switch (day) {
      case "Sunday":
        number = 7;
        break;
      case "Monday":
        number = 1;
        break;
      case "Tuesday":
        number = 2;
        break;
      case "Wednesday":
        number = 3;
        break;
      case "Thursday":
        number = 4;
        break;
      case "Friday":
        number = 5;
        break;
      case "Saturday":
        number = 6;
        break;
      default:
        number = 0;
    }

    return number;
  };

  const handleDateInterval = (newValue: any) => {
    if (!!newValue && newValue.length > 1 && !!newValue[1]) {
      setShiftSeries({
        ...shiftSeries,
        series_start_date: format(newValue[0].toDate(), "MM-dd-yyyy"),
        series_end_date: format(newValue[1].toDate(), "MM-dd-yyyy"),
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "calc(100% - 32px)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "1px solid #F1F2F4",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "98%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#17191C",
              lineHeight: "24.3px",
            }}
          >
            Date and duration
          </Typography>
          <Chip
            sx={{
              backgroundColor: "#F9FAFA",
              border: "1px solid #F1F2F4",
              color: "#8F98A3",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "22.4px",
              borderRadius: "10px !important",
            }}
            label="0h per week"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#737F8C",
              justifyContent: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography
                text="Dates interval"
                required={true}
                sx={{
                  fontWeight: "600",
                  color: "#737F8C",
                  marginBottom: "-7px",
                }}
              />
            </LableTextFieldBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["SingleInputDateRangeField"]}>
                <DateRangePicker
                  sx={{
                    justifyContent: "center",
                    background: "#F5FAFE",
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: "#0D80F2 !important",
                    },
                    ".MuiInputBase-input": {
                      padding: "8.5px 14px !important",
                      fontSize: "16px",
                      fontWeight: "400",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #0D80F2",
                      borderRadius: "8px",
                    },
                    ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "2px solid #0D80F2 !important",
                      borderRadius: "8px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #CFE5FC",
                      borderRadius: "8px",
                    },
                    "& .MuiInputLabel-root": { display: "none" },
                    Input: {
                      "&::placeholder": {
                        color: "#6EB2F7",
                        opacity: "5",
                      },
                    },
                  }}
                  slotProps={{
                    textField: {
                        inputProps: {
                          readOnly: true,
                        },
                      InputProps: {
                        endAdornment: (
                          <CalendarTodayOutlinedIcon
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      },
                      placeholder: "Start dates interval",
                    },
                  }}
                  slots={{ field: SingleInputDateRangeField }}
                  name="allowedRange"
                  format="MMM DD, YYYY"
                  onChange={(newValue) => {
                    handleDateInterval(newValue);
                  }}
                  disablePast={true}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography
                text="Series presets"
                required={false}
              />
            </LableTextFieldBox>
            <CalendarDropDown
              disabled
              sx={{
                ".MuiInputBase-input": {
                  color: "#6EB2F7  !important",
                },

                "&.Mui-disabled": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ABB2BA !important",
                  },
                },
              }}
              handleChange={function (e: any): void {}}
              value={1}
            >
              <MenuItem value={1}> Select the preset </MenuItem>
            </CalendarDropDown>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <OverFlowBox sx={{ width: "100%" }}>
            {dayList.map((day: string) => (
              <CalendarSeriesShiftDayTime
                key={day}
                day={day}
                dayNumber={getDayNumber(day)}
                shiftSeriesDayForm={shiftSeriesDayForm}
                handleToggleClick={handleToggleClick}
                handleSeriesStartTimeChange={handleSeriesStartTimeChange}
                handleSeriesEndTimeChange={handleSeriesEndTimeChange}
                handleAddSeriesClick={handleAddSeriesClick}
                handleRemoveSeriesClick={handleRemoveSeriesClick}
                handleCopySeriesClick={handleCopySeriesClick}
              />
            ))}
          </OverFlowBox>
        </Box>
        <Box
          sx={{
            width: "100%",
            color: "#737F8C",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography text="Time preset" required={false} />
            </LableTextFieldBox>
            <Box
              sx={{
                paddingTop: "1%",
                cursor: "pointer",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                  fill="#737F8C"
                />
              </svg>
            </Box>
          </Box>

          <FormControlLabel
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "16px !important",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#000000DE",
              },
            }}
            control={
              <Switch
              disabled
                sx={{
                  width: "58px",
                  ".Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  ".Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#0D80F2 !important",
                  },
                }}
                checked={false}                
              />
            }
            label="Save as preset"
          />
        </Box>
      </Box>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  ContainedButton,
  ErrorAlert,
  OutlinedButton,
  OverFlowBox,
  PlayCircleIcon,
  SuccessAlert,
  Typography12_400,
  Typography18_600,
} from "../../atom";
import { OrganizationDetailForm } from "./organisationDetailForm";
import { OrgContactInfoForm } from "./orgContactInfoForm";
import { OrgBrandingForm } from "./orgBrandingForm";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  errorAlertState,
  errorMessageState,
  loadingIconState,
  organizationDetailState,
  successAlertState,
  userDetailstate,
} from "../../../libs/state-management-lib";
import {
  generateToken,
  getNumbeOfStaffBeds,
  getNumberOfEmployee,
  getOrganizationByUserId,
  getStaffTypeList,
  getUserDetail,
  updateOrganization,
} from "../../../libs/services-lib";
import { OrganizationDetail } from "../../../libs/model-lib";
import Joi from "joi";
import { useShallow } from "zustand/react/shallow";
import { useNavigate } from "react-router-dom";
import { EmptyGuid, EmptyOrganizationDetail } from "../../../libs/common-lib";
import { OrganizationDetailContext } from "../../../libs/context-lib/organizationDetailContext";

export const OrganisationSetting = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const timerId = useRef<any>();
  const [organizationDetail, setOrganizationDetail] = useState<OrganizationDetail>(EmptyOrganizationDetail);

  useLayoutEffect(() => {
    const userDetailStateSub = userDetailstate.subscribe(async (state) => {
      await getOrganizationByUserId(state.id);
    });

    return () => {
      userDetailStateSub();
    };
  }, []);

  useEffect(() => {
    refreshEvent();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }
  }, [errorMessage]);

  const schema = Joi.object<OrganizationDetail>({
    id: Joi.string().allow(""),
    name: Joi.string().required().max(255).messages({
      "string.empty": `Organization name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    npi: Joi.string().min(10).max(10).allow("").messages({
      "string.min": `NPI should be 10 digit`,
      "string.max": `NPI should be 10 digit`,
    }),
    definitive_id: Joi.string().allow(""),
    medicare_provider_number: Joi.string().allow(""),
    number_of_staffed_beds: Joi.number().allow(0),
    number_of_employee: Joi.number().allow(0),
    address_1: Joi.string().required().max(255).messages({
      "string.empty": `Home address 1 cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_2: Joi.string().allow("").max(255).messages({    
      "string.max": `The maximum character limit should be 255`,
    }),
    city: Joi.string().required().max(255).messages({
      "string.empty": `City cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    state: Joi.string().required().max(255).messages({
      "string.empty": `State cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    postal_code: Joi.string().min(5).max(5).required().messages({
      "string.empty": `Postal code cannot be an empty field`,
      "string.min": `Postal code is invalid`,
      "string.max": `Postal code is invalid`,
    }),
    contact_name: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    contact_phone_number: Joi.string().allow(""),
    contact_email: Joi.string().allow("").max(255).email({ tlds: false }).messages({
      "string.email": `Email address is invalid`,
      "string.max": `The maximum character limit should be 255`,
    }),
    contact_title: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    tenant_id: Joi.string().allow(""),
    primary_phone_number: Joi.string().allow(""),
    website: Joi.string().uri().allow("").messages({
      "string.uri": "Website is invalid",
    }),
    is_active: Joi.boolean(),
    staff_types: Joi.array().empty(Joi.array().length(0)),
    contact_person_email: Joi.string().allow(""),
    facility_type: Joi.number().allow(0),
    invitation_id: Joi.string().allow(null),
  }).options({ abortEarly: false });

  const handleOrgSaveClick = async () => {
    const result = schema.validate(organizationDetail);
    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        errorMessage.push({ field: cc.path[0], errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);

    loadingIconState.setState(true);
    let orgDetail = organizationDetail;
    if (orgDetail.primary_phone_number == "+") {
      orgDetail.primary_phone_number = "";
    }
    if (orgDetail.contact_phone_number == "+") {
      orgDetail.contact_phone_number = "";
    }
    await updateOrganization(organizationDetail);
    organizationDetailState.setState(organizationDetail);
    loadingIconState.setState(false);
  };

  const refreshEvent = () => {
    (async function () {
      loadingIconState.setState(true);
      if (
        !!userDetailstate.getState() &&
        !!userDetailstate.getState().id &&
        userDetailstate.getState().id != EmptyGuid
      ) {

        let orgDetail = await getOrganizationByUserId(userDetailstate.getState().id);
        setOrganizationDetail(orgDetail);

        await Promise.all([
          getNumbeOfStaffBeds(),
          getNumberOfEmployee(),
          getStaffTypeList(),
        ]);
      }
      else {
        await Promise.all([
          getUserDetail(),
          getNumbeOfStaffBeds(),
          getNumberOfEmployee(),
          getStaffTypeList(),
        ]);
      }
     
      loadingIconState.setState(false);
    })();
  };

  const handleOrgCancelClick = () => {
    setErrorMessage([]);
    refreshEvent();
  };

  const handleWebsiteValidate = () => {};

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
      >
        <Box
          sx={{
            width: "38%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "16px 24px 16px 24px",
            gap: "10px",
            borderRadius: "8px",
            backgroundColor: "#FEF8F5",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        >
          <PlayCircleIcon
            sx={{
              color: "#F7906E",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                alignItems: "center",
                color: "#F7906E",
              }}
            >
              Setting up organization
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                alignItems: "center",
                color: "#F7906E",
                lineHeight: "29px",
                paddingLeft: "5px",
              }}
            >
              1 min.
            </Typography>
          </Box>
          <CloseOutlinedIcon
            sx={{
              color: "#F7906E",
              top: "5.2px",
              left: "5.2px",
              alignItems: "flex-end",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px 16px 16px 28px",
            gap: "16px",
            borderBottom: "1px solid #CFE5FC",
            width: "100%",
          }}
        >
          <Typography18_600 text="Add Organization" color="#17191C" />
        </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "calc(100% - 36px)",
            padding: "24px 18px 34px 18px",
            gap: "10px",
            width: "calc(100% - 58px)",
          }}
        >
          <OverFlowBox
            sx={{
              display: "flex",
              flexDirection: "column",
              background: (theme) => theme.palette.secondary.main,
              borderRight: "1px solid #CFE6FC",
              width: "100%",
              height: "100%",
            }}
          >
            <OrganizationDetailContext.Provider
              value={{ organizationDetail, setOrganizationDetail }}
            >
              <OrganizationDetailForm errorMessage={errorMessage} />
              <OrgContactInfoForm errorMessage={errorMessage} />
            </OrganizationDetailContext.Provider>

            {/* <OrgBrandingForm /> */}
          </OverFlowBox>
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "16px",
          width: "95%",
          borderTop: "1px solid #CFE5FC",
          padding: "16px 16px 16px 28px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px" }}
            text="Cancel"
            handleClick={handleOrgCancelClick}
          />
          <ContainedButton
            sx={{ height: "40px" }}
            text="Save"
            handleClick={handleOrgSaveClick}
          />
        </Box>
      </Box>
    </>
  );
};

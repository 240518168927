import { Box, Tab, Tabs, Typography } from "@mui/material";
import {
  ActiveStatusAlert,
  ContainedButton,
  ErrorAlert,
  FilterIcon,
  SettingBox,
  SuccessAlert,
  UpdateAlert,
  SearchTextField,
  PlayCircleIcon,
} from "../../atom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { LocationDashboard } from "./locationDashboard";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  activeConfirmationPopupState,
  activeLocationTabState,
  activeStatusAlertState,
  departmentDetailState,
  departmentListState,
  errorAlertState,
  errorMessageState,
  inActiveConfirmationPopupState,
  loadingIconState,
  locationDetailState,
  organizationDetailState,
  successAlertState,
  unitDetailState,
  updateAlertState,
} from "../../../libs/state-management-lib";
import {
  getAllDepartmentsByOrgId,
  getAllLocationsByOrgId,
  getDepartmentById,
  getDepartmentByOrganizationId,
  getDepartmentTypeList,
  getLocationById,
  getLocationTypeList,
  getOrganizationRoles,
  getRoleTypeList,
  getSubDepartmentById,
  getSubDepartmentByOrgId,
  getSubDepartmentList,
  getTimeZoneList,
  getWeekDayStartList,
  updateDepartment,
  updateLocation,
  updateUnit,
} from "../../../libs/services-lib";
import { Decryption, EmptyGuid, Encryption } from "../../../libs/common-lib";
import { DepartmentDashboard } from "./departmentDashboard";
import { UnitDashboard } from "./unitDashboard";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const LocationSetting = () => {
  const [locTabvalue, setLocTabValue] = useState<number>(0);
  const navigate = useNavigate();
  const { id } = useParams();


  useEffect(() => {
    refreshEvent();
    setLocTabValue(!!id ? parseInt(Decryption(id)) : 0);
    navigate("/settings/locations-departments");
  }, []);

  const handleAddLocationClick = () => {
    navigate(`/settings/location/${Encryption(EmptyGuid)}`);
  };

  const handleAddDepartmentClick = async () => {
    navigate(`/settings/department/${Encryption(EmptyGuid)}`);
  };

  const handleAddUnitClick = async () => {
    navigate(`/settings/sub-department/${Encryption(EmptyGuid)}`);
  };

  const handleActiveLocation = async (activeLocationId: string) => {
    loadingIconState.setState(true);
    await getLocationById(activeLocationId);
    let locationDetail = locationDetailState.getState();
    locationDetail.is_active = false;
    locationDetail.locationContactDetails.is_active = false;
    try {
      await updateLocation(locationDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveLocation = async (InActiveLocationId: string) => {
    loadingIconState.setState(true);
    await getLocationById(InActiveLocationId);
    let locationDetail = locationDetailState.getState();
    locationDetail.is_active = true;
    locationDetail.locationContactDetails.is_active = true;
    try {
      await updateLocation(locationDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  const handleActiveDepartment = async (activeDepartmentId: string) => {
    loadingIconState.setState(true);
    await getDepartmentById(activeDepartmentId);
    let departmentDetail = departmentDetailState.getState();
    departmentDetail.is_active = false;
    try {
      await updateDepartment(departmentDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveDepartment = async (activeDepartmentId: string) => {
    loadingIconState.setState(true);
    await getDepartmentById(activeDepartmentId);
    let departmentDetail = departmentDetailState.getState();
    departmentDetail.is_active = true;
    try {
      await updateDepartment(departmentDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  const handleActiveUnit = async (activeUnitId: string) => {
    loadingIconState.setState(true);
    await getSubDepartmentById(activeUnitId);
    let unitDetail = unitDetailState.getState();
    unitDetail.is_active = false;
    try {
      await updateUnit(unitDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveUnit = async (inActiveUnitId: string) => {
    loadingIconState.setState(true);
    await getSubDepartmentById(inActiveUnitId);
    let unitDetail = unitDetailState.getState();
    unitDetail.is_active = true;
    try {
      await updateUnit(unitDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setLocTabValue(newValue);
    activeLocationTabState.setState(newValue);
    refreshEvent();
  };

  const handleLocationDashboardCallBack = async (locId: string) => {
    navigate(`/settings/location/${Encryption(locId)}`);
  };

  const handleEditDepartmentCallBack = async (depId: string) => {
    navigate(`/settings/department/${Encryption(depId)}`);
  };

  const handleEditUnitCallBack = async (unitId: string) => {
    navigate(`/settings/sub-department/${Encryption(unitId)}`);
  };

  const refreshEvent = () => {
    (async function () {
      loadingIconState.setState(true);
      await Promise.all([
        getAllLocationsByOrgId(organizationDetailState.getState().id),
        getDepartmentByOrganizationId(organizationDetailState.getState().id),
        getOrganizationRoles(organizationDetailState.getState().id),
        getSubDepartmentByOrgId(organizationDetailState.getState().id),
        getLocationTypeList(),
        getWeekDayStartList(),
        getTimeZoneList(),
        getDepartmentTypeList(),
        getSubDepartmentList(),
      ]);
      loadingIconState.setState(false);
    })();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "38%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "16px 24px 16px 24px",
            gap: "10px",
            borderRadius: "8px",
            backgroundColor: "#FEF8F5",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        >
          <PlayCircleIcon
            sx={{
              color: "#F7906E",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                alignItems: "center",
                color: "#F7906E",
              }}
            >
              Setting up locations and departments
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                alignItems: "center",
                color: "#F7906E",
                lineHeight: "29px",
                paddingLeft: "5px",
              }}
            >
              1 min.
            </Typography>
          </Box>
          <CloseOutlinedIcon
            sx={{
              color: "#F7906E",
              top: "5.2px",
              left: "5.2px",
              alignItems: "flex-end",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #CFE5FC",
            padding: "16px 16px 0px 0px",
            gap: "16px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#0D80F2",
                },
              }}
              value={locTabvalue}
              onChange={handleChange}
              sx={{
                fontWeight: 700,
                fontSize: "18px",
                alignItems: "center",
                color: "#17191C !important",
                ".Mui-selected": {
                  color: "#0D80F2 !important",
                  fontWeight: 700,
                  fontSize: "18px",
                },
                ".MuiButtonBase-root": {
                  textTransform: "none",
                  fontWeight: 700,
                  fontSize: "18px",
                },
              }}
            >
              <Tab label="Locations" />
              <Tab label="Departments" />
              <Tab label="Sub-departments" />
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "26px",
              justifyContent: "flex-end",
              paddingBottom: "3px",
            }}
          >
            <SearchTextField
              placeholder={"Search"}
              value={""}
              handleChange={(e: any) => {}}
            />
            <FilterIcon
              sx={{ 
                // color: "#0D80F2",                 
                // cursor: "pointer",
                marginTop: "2%", 
               color:"#ABB2BA"
              }}
            />
            {locTabvalue == 0 && (
              <ContainedButton
                text="Add location"
                startIcon={
                  <AddIcon
                    sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                  />
                }
                sx={{ height: "40px" }}
                handleClick={handleAddLocationClick}
              />
            )}
            {locTabvalue == 1 && (
              <ContainedButton
                text="Add department"
                startIcon={
                  <AddIcon
                    sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                  />
                }
                sx={{ height: "40px" }}
                handleClick={handleAddDepartmentClick}
              />
            )}
            {locTabvalue == 2 && (
              <ContainedButton
                text="Add sub-department"
                startIcon={
                  <AddIcon
                    sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                  />
                }
                sx={{ height: "40px" }}
                handleClick={handleAddUnitClick}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "10px",
            gap: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          <SettingBox>
            {locTabvalue == 0 && (
              <LocationDashboard
                handleLocationDashboardCallBack={
                  handleLocationDashboardCallBack
                }
                handleActiveLocation={handleActiveLocation}
                handleInActiveLocation={handleInActiveLocation}
                handleAddLocation={handleAddLocationClick}
              />
            )}
            {locTabvalue == 1 && (
              <DepartmentDashboard
                handleEditDepartmentCallBack={handleEditDepartmentCallBack}
                handleActiveDepartment={handleActiveDepartment}
                handleInActiveDepartment={handleInActiveDepartment}
                handleAddDepartment={handleAddDepartmentClick}
              />
            )}
            {locTabvalue == 2 && (
              <UnitDashboard
                handleEditUnitCallBack={handleEditUnitCallBack}
                handleActiveUnit={handleActiveUnit}
                handleInActiveUnit={handleInActiveUnit}
                handleAddUnit={handleAddUnitClick}
              />
            )}
          </SettingBox>
        </Box>
      </Box>
    </>
  );
};

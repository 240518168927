import { createContext, useContext } from "react";
import { StaffDetail } from "../model-lib";

interface IStaffDetailContext {
  staffDetail: StaffDetail;
  setStaffDetail: React.Dispatch<React.SetStateAction<StaffDetail>>;
}

export const StaffDetailContext = createContext<
  IStaffDetailContext | undefined
>(undefined);

export const useStaffDetailContext = (): IStaffDetailContext => {
  const context = useContext(StaffDetailContext);
  if (context === undefined) {
    throw new Error("useStaffDetailContext must be used within a MyProvider");
  }
  return context;
};

import { Box, Checkbox, MenuItem } from "@mui/material";
import {
  DropdownWithBorder,
  FormContainerBox,
  FormPlacementBox,
  InfoIcon,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextAreaField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography14_400,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  DepartmentDetail,
  LocationDetail,
  UnitDetail,
} from "../../../libs/model-lib";
import {
  departmentListState,
  locationDetailListState,
  unitDetailState,
} from "../../../libs/state-management-lib";
import { useShallow } from "zustand/react/shallow";
import { EmptyGuid, getErrorDetail } from "../../../libs/common-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { useUnitDetailContext } from "../../../libs/context-lib/unitDetailContext";

interface IUnitDetailForm {
  errorMessage: any[];
}
export const UnitDetailForm = (props: IUnitDetailForm) => {
  const [locDetailList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );

  const [departmentList] = useState<DepartmentDetail[]>(
    departmentListState.getState().departmentDetailList
  );

  const { unitDetail, setUnitDetail } = useUnitDetailContext();

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Sub-department details" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Sub-department name"
                  color="#737F8C"
                  required
                />
                <PlatformTextField
                  placeholder={"Enter sub-department name"}
                  value={unitDetail.name}
                  handleChange={(e: any) => {
                    setUnitDetail({
                      ...unitDetail,
                      name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Location & Department" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Location"
                  color="#737F8C"
                  required
                  infoIcon
                  toolTip={
                    "A sub-department has been created in the chosen location."
                  }
                />
                <DropdownWithBorder
                  sx={{
                    "&.Mui-disabled": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #ABB2BA",
                      },
                    },
                  }}
                  value={""}
                  handleChange={(e: any) => {}}
                  disable={true}
                >
                  <MenuItem value={""} disabled>
                    Select location
                  </MenuItem>
                  {locDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.name}
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Department"
                  color="#737F8C"
                  required
                  infoIcon
                  toolTip={
                    "A sub-department has been created in the chosen department."
                  }
                />
                <DropdownWithBorder
                  value={unitDetail.department_id}
                  handleChange={(e: any) => {
                    setUnitDetail({
                      ...unitDetail,
                      department_id: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "department_id",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={""}>Select department</MenuItem>
                  {departmentList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Main department contact" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              onChange={(e: any) => {}}
              inputProps={{ "aria-label": "controlled" }}
              disabled
            />
            <Typography16_400 text="Same as department" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter name"}
                  value={""}
                  handleChange={(e: any) => {}}
                  disabled={true}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Title" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter title"}
                  value={""}
                  handleChange={(e: any) => {}}
                  disabled={true}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Phone number" color="#737F8C" />
                <PhoneNumberTextField
                  placeholder="Enter phone number"
                  value={""}
                  handleChange={(e: any) => {}}
                  disabled={true}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Email" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter email"}
                  value={""}
                  handleChange={(e: any) => {}}
                  disabled={true}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Note" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "100%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Notes" color="#737F8C" />
                <TextAreaField
                  Label="Add notes..."
                  value=""
                  handleChange={(e: any) => {}}
                  disabled={true}
                />
              </LableTextFieldBox>
              <Typography14_400
                text="Notes will be displayed to other users, agency partners, and talent to provide details about the sub-department"
                color="#737F8C"
              />
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AutoCompleteTextInput,
  CloseIcon,
  ContainedButton,
  ContainedDeleteButton,
  DeleteConfirmationPopup,
  LableTextFieldBox,
  MultiSelectDropdown,
  OutlinedButton,
  OverFlowBox,
  PlatformTextField,
  SearchTextField,
  Typography14_400,
  Typography14_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import { TimeValidationError } from "@mui/x-date-pickers";
import { CalendarTextAreaField } from "../../atom/textField/calendarTextAreaField";
import { CalendarAssignStaffModal } from "./calendarAssignStaffModal";
import {
  CustomTag,
  DepartmentDetail,
  GenericMasterData,
  LocationDetail,
  OrgRole,
  SearchStaffCriteria,
  Shift,
  ShiftAssignment,
  ShiftDetail,
  ShiftRotation,
  ShiftSeries,
  StaffDetail,
  StaffFilterColumnEnum,
  StaffFilterOperatorEnum,
  StaffLocationMappings,
  UnitDetail,
} from "../../../libs/model-lib";
import { locationDetailListState } from "../../../libs/state-management-lib/locationState";
import {
  emptyCustomTag,
  EmptyGuid,
  emptyShift,
  emptyShiftRotation,
  emptyShiftSeries,
  emptyStaffDetail,
} from "../../../libs/common-lib";
import {
  loadingIconState,
  organizationDetailState,
  orgRoleListState,
  shiftDetailState,
  shiftRotationState,
  shiftSeriesState,
  staffTypeListState,
} from "../../../libs/state-management-lib";
import {
  cancelShift,
  createRotationShift,
  createShift,
  createShiftSeries,
  deleteShift,
  getAllDepartmentsByLocationId,
  getAllShifts,
  getCustomTagByOrgId,
  getMappedAgency,
  getStaffListBySearchCriteria,
  getSubDepartmentByDepartmentId,
  getSubDepartmentByLocationId,
  updateShift,
} from "../../../libs/services-lib";
import { format } from "date-fns";
import { CalendarSeriesShiftDateForm } from "./calendarSeriesShiftForm";
import { CalendarRotationShiftDateForm } from "./calendarRotationShiftForm";
import { CalendarShiftTimePickerModal } from "./calendarShiftTimePickerModal";
import { AgencyDetail } from "../../../libs/model-lib/agencyDetail";
import { NumberInputField } from "../../atom/textField/numberInputTextField";
import { CalendarSingleShiftDateForm } from "./calendarSingleShiftForm";
import {
  ShiftDetailContext,
  ShiftRoationContext,
  ShiftSeriesContext,
  useSearchShiftContext,
} from "../../../libs/context-lib/shiftDetailContext";
import { CalendarAssignStaffListViewBox } from "../../atom/box/calendarBoxes/calendarAssignStaffListViewBox";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { CalendarRotationTalentBox } from "../../atom/box/calendarBoxes/calendarRotationTalentBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseDropDownIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import SearchIconText from "@mui/icons-material/Search";
import CalendarTalentForm from "../../molecule/calendarTalentForm";

interface ICalendarAddShiftContainer {
  handleCancelClick: () => void;
  shiftDetail: Shift;
  orginalShiftDetail: string;
  locationId: string;
  shiftDate: Dayjs | null;
  staffDetail?: StaffDetail | null;
  customTagList: CustomTag[];
  addShiftSelection: number;
}

export const CalendarAddShitContainer = (props: ICalendarAddShiftContainer) => {
  const [locationList, setLocationList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [shiftDetail, setShiftDetail] = useState<Shift>(props.shiftDetail);
  const [locationId, setLocationId] = useState<string>(props.locationId);
  const [orgRoleList, setOrgRoleList] = useState<OrgRole[]>(
    orgRoleListState.getState().orgRoleList
  );
  const [shiftType, setShiftType] = useState<number>(0);
  const [staffTypeList, setStaffTypeList] = useState<GenericMasterData[]>(
    staffTypeListState.getState().staffTypeList
  );
  const [assignedStaffDetail, setAssignedStaffDetail] = useState<StaffDetail[]>(
    !!props.staffDetail ? [props.staffDetail] : []
  );

  const [shiftStartTime, setShiftStartTime] = useState<Dayjs | null>(null);
  const [shiftEndTime, setShiftEndTime] = useState<Dayjs | null>(null);
  const [subDepartmentList, setSubDepartmentList] = useState<UnitDetail[]>([]);
  const [filteredStaffList, setFilteredStaffList] = useState<StaffDetail[]>([]);
  const [nonFilteredStaffList, setNonFilteredStaffList] = useState<
    StaffDetail[]
  >([]);
  const [shiftSeries, setShiftSeries] = useState<ShiftSeries>(emptyShiftSeries);
  const [assignType, setAssignType] = useState<number>(0);
  const [selectedStaffIds, setSelectedStaffIds] = useState<string[]>([]);
  const [searchAssignee, setSearchAssignee] = useState<string>("");

  const [showDeleteConfirmationPopup, setshowDeleteConfirmationPopup] =
    useState(false);
  const [showCancelConfirmationPopup, setShowCancelConfirmationPopup] =
    useState(false);
  const [showDiscardConfirmationPopup, setShowDiscardConfirmationPopup] =
    useState(false);
  const [shiftRotation, setShiftRotation] =
    useState<ShiftRotation>(emptyShiftRotation);
  const [showPublishButton, setShowPublishButton] = useState(0);
  const { searchShift, setAllShifts } = useSearchShiftContext();
  const [agencyName, setAgencyName] = useState<string>("");

  useEffect(() => {
    (async function () {
      if (props.locationId != EmptyGuid) {
        let departmentList = await getAllDepartmentsByLocationId(
          props.locationId
        );

        setDepartmentList(departmentList);

        if (
          !!props.shiftDetail.department_id &&
          props.shiftDetail.department_id != EmptyGuid
        ) {
          let subDepartmentList = await getSubDepartmentByDepartmentId(
            props.shiftDetail.department_id
          );
          setSubDepartmentList(subDepartmentList);
        }

        setLocationId(props.locationId);
      }
    })();
  }, [props.locationId]);

  useEffect(() => {
    if (
      !!props.shiftDetail.role_ids &&
      props.shiftDetail.role_ids.length > 0 &&
      !!props.shiftDetail.staff_type_ids &&
      props.shiftDetail.staff_type_ids.length > 0
    ) {
      (async function () {
        await getStaffDetailsByDeptId(
          props.shiftDetail.staff_type_ids,
          props.shiftDetail.role_ids
        );
      })();
    }

    if (props.shiftDetail.id != EmptyGuid) {
      setShiftType(1);

      let startDate = format(props.shiftDetail.start_date, "h aa");

      if (parseInt(format(props.shiftDetail.start_date, "mm")) > 0) {
        startDate = format(props.shiftDetail.start_date, "h:mm aa");
      }

      let endDate = format(props.shiftDetail.end_date, "h aa");

      if (parseInt(format(props.shiftDetail.end_date, "mm")) > 0) {
        endDate = format(props.shiftDetail.end_date, "h:mm aa");
      }

      setShiftStartTime(dayjs(props.shiftDetail.start_date));
      setShiftEndTime(dayjs(props.shiftDetail.end_date));
    }
  }, [props.shiftDetail]);

  useEffect(() => {
    let selectedIds: string[] = [];
    let agencyName: string = "";

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      assignedStaffDetail.forEach((dd) => {
        selectedIds.push(dd.id);
        if (
          !!dd.agencyDetails &&
          dd.agencyDetails.id != EmptyGuid &&
          !!dd.agencyDetails.name
        ) {
          if (!!agencyName && !agencyName.includes(dd.agencyDetails.name)) {
            agencyName = `${agencyName}, ${dd.agencyDetails.name}`;
          } else {
            agencyName = dd.agencyDetails.name;
          }
        }
      });
    }

    setSelectedStaffIds(selectedIds);
    setAgencyName(agencyName);
  }, [assignedStaffDetail]);

  const handleSelectStaffClick = async (e: any) => {
    setShiftDetail({
      ...shiftDetail,
      staff_type_ids: [parseInt(e.target.value)],
    });
    await getStaffDetailsByDeptId(
      [parseInt(e.target.value)],
      shiftDetail.role_ids
    );
    setShowPublishButton(e.target.value);
  };

  const handleLocationChange = async (e: any) => {
    setLocationId(e.target.value);

    setShiftDetail({
      ...shiftDetail,
      department_id: EmptyGuid,
      sub_department_id: null,
    });

    if (!!!e.target.value || e.target.value == EmptyGuid) {
      setDepartmentList([]);
      setSubDepartmentList([]);
      return;
    }

    let departmentList = await getAllDepartmentsByLocationId(e.target.value);
    setDepartmentList(departmentList);

    let subDepartmentList = await getSubDepartmentByLocationId(e.target.value);
    setSubDepartmentList(subDepartmentList);

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      let finalList: StaffDetail[] = [];

      assignedStaffDetail.forEach((staffDetail: StaffDetail) => {
        if (
          !!staffDetail.staffLocationMappings &&
          staffDetail.staffLocationMappings.length > 0
        ) {
          if (
            staffDetail.staffLocationMappings.filter(
              (dd) => dd.location_id == e.target.value
            ).length > 0
          ) {
            finalList.push(staffDetail);
          }
        } else {
          finalList.push(staffDetail);
        }
      });

      setAssignedStaffDetail(finalList);
    }
  };

  const handleRoleChange = async (e: any) => {
    if (e.target.value.find((ee: string) => ee == EmptyGuid)) {
      setAssignedStaffDetail([]);
      return;
    }

    if (!!e.target.value && e.target.value.length == 0) {
      setAssignedStaffDetail([]);
    }

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      let finalList: StaffDetail[] = [];

      for (let i = 0; i < e.target.value.length; i++) {
        assignedStaffDetail.forEach((staffDetail: StaffDetail) => {
          if (
            !!staffDetail.role_ids.find((dd: number) => dd == e.target.value[i])
          ) {
            finalList.push(staffDetail);
          }
        });
      }

      setAssignedStaffDetail(finalList);
    }

    setShiftDetail({ ...shiftDetail, role_ids: e.target.value });
    await getStaffDetailsByDeptId(shiftDetail.staff_type_ids, e.target.value);
  };

  const handleRoleRemove = async (e: any) => {
    let roleList = shiftDetail.role_ids.filter((cc) => cc != e);

    if (!!roleList && roleList.length == 0) {
      setAssignedStaffDetail([]);
    }

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      let finalList: StaffDetail[] = [];

      for (let i = 0; i < roleList.length; i++) {
        assignedStaffDetail.forEach((staffDetail: StaffDetail) => {
          if (
            !!staffDetail.role_ids.find((dd: number) => dd == e.target.value[i])
          ) {
            finalList.push(staffDetail);
          }
        });
      }

      setAssignedStaffDetail(finalList);
    }

    setShiftDetail({ ...shiftDetail, role_ids: roleList });
    await getStaffDetailsByDeptId(shiftDetail.staff_type_ids, roleList);
  };

  const handleAddShiftClick = async () => {
    if (shiftType == 1) {
      await handleAddSingleShiftClick();
    }

    if (shiftType == 2) {
      await handleAddSeriesShiftClick();
    }
    if (shiftType == 3) {
      await handleAddRotationShiftClick();
    }
  };

  const handleAddSeriesShiftClick = async () => {
    await loadingIconState.setState(true);
    let newShiftSeriesDetail = { ...shiftSeries };

    newShiftSeriesDetail.break_types = [93];
    newShiftSeriesDetail.department_ids = [shiftDetail.department_id];
    newShiftSeriesDetail.is_same_staff_member = false;
    newShiftSeriesDetail.role_ids = shiftDetail.role_ids;
    newShiftSeriesDetail.no_of_shifts = 1;
    newShiftSeriesDetail.staff_types = shiftDetail.staff_type_ids;
    newShiftSeriesDetail.recurrence_period = 100;
    if (
      !!shiftDetail.sub_department_id &&
      shiftDetail.sub_department_id != EmptyGuid
    ) {
      newShiftSeriesDetail.sub_department_ids = [shiftDetail.sub_department_id];
    } else {
      newShiftSeriesDetail.sub_department_ids = [];
    }

    newShiftSeriesDetail.notes = shiftDetail.shiftDetails.description;

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      // assignedStaffDetail.forEach((staffDetail: StaffDetail) => {

      // });
      newShiftSeriesDetail.is_same_staff_member = true;
      newShiftSeriesDetail.staff_id = assignedStaffDetail[0].id;
    } else {
      newShiftSeriesDetail.staff_id = EmptyGuid;
    }

    let createdShiftDetails = await createShiftSeries(newShiftSeriesDetail);

    if (!!!createdShiftDetails) {
      await loadingIconState.setState(false);
      return;
    }

    await loadingIconState.setState(false);
    props.handleCancelClick();
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);
    if (
      !!shiftDetail.custom_tag_name &&
      (!!!shiftDetail.custom_tag_id || shiftDetail.custom_tag_id <= 0)
    ) {
      await getCustomTagByOrgId(organizationDetailState.getState().id);
    }
    await loadingIconState.setState(false);
    setShiftDetail(emptyShift);
    shiftDetailState.setState(emptyShift);
    setShiftSeries(emptyShiftSeries);
    shiftSeriesState.setState(emptyShiftSeries);
  };

  const handleAddSingleShiftClick = async () => {
    await loadingIconState.setState(true);
    let newShiftDetail = { ...shiftDetail };

    if (!!shiftDetail.start_date_string) {
      newShiftDetail.start_date_string = format(
        shiftDetail.start_date,
        "yyyy-MM-dd HH:mm:SS"
      );
    } else {
      newShiftDetail.start_date_string = "";
    }
    if (!!shiftDetail.end_date_string) {
      newShiftDetail.end_date_string = format(
        shiftDetail.end_date,
        "yyyy-MM-dd HH:mm:SS"
      );
    } else {
      newShiftDetail.end_date_string = "";
    }

    newShiftDetail.type_of_shift = 90;
    newShiftDetail.shift_break_types = [93];

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      newShiftDetail.staff_detail_list = assignedStaffDetail;
    } else {
      newShiftDetail.staff_detail_list = [];
    }

    if (shiftDetail.id != EmptyGuid) {

      if(shiftDetail.shift_status == 4) {
        newShiftDetail.shift_status =
        !!assignedStaffDetail && assignedStaffDetail.length > 0 ? 1 : 2;
      }
      
      let updatedShiftData = await updateShift(newShiftDetail);

      if (!!!updatedShiftData) {
        await loadingIconState.setState(false);
        return;
      }
    } else {
      newShiftDetail.shift_status = 2;

      let createdShiftDetails = await createShift(newShiftDetail);

      if (!!!createdShiftDetails) {
        await loadingIconState.setState(false);
        return;
      }
    }

    props.handleCancelClick();
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);

    if (
      !!shiftDetail.custom_tag_name &&
      (!!!shiftDetail.custom_tag_id || shiftDetail.custom_tag_id <= 0)
    ) {
      await getCustomTagByOrgId(organizationDetailState.getState().id);
    }

    await loadingIconState.setState(false);
    setShiftDetail(emptyShift);
  };

  const handlePublishShiftClick = async () => {
    await loadingIconState.setState(true);
    let newShiftDetail = { ...shiftDetail };
    newShiftDetail.start_date_string = format(
      shiftDetail.start_date,
      "yyyy-MM-dd HH:mm:SS"
    );
    newShiftDetail.end_date_string = format(
      shiftDetail.end_date,
      "yyyy-MM-dd HH:mm:SS"
    );

    newShiftDetail.type_of_shift = 90;
    newShiftDetail.shift_break_types = [93];
    newShiftDetail.is_publish = true;

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      newShiftDetail.staff_detail_list = assignedStaffDetail;
      newShiftDetail.shift_status = 1;
    } else {
      newShiftDetail.staff_detail_list = [];
      newShiftDetail.shift_status = 2;
    }

    let createdShiftDetails = await createShift(newShiftDetail);

    if (!!!createdShiftDetails) {
      await loadingIconState.setState(false);
      return;
    }

    props.handleCancelClick();
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);

    if (
      !!shiftDetail.custom_tag_name &&
      (!!!shiftDetail.custom_tag_id || shiftDetail.custom_tag_id <= 0)
    ) {
      await getCustomTagByOrgId(organizationDetailState.getState().id);
    }
    await loadingIconState.setState(false);
    setShiftDetail(emptyShift);
  };

  const handleAddRotationShiftClick = async () => {
    await loadingIconState.setState(true);
    let newShiftRotationDetail = { ...shiftRotation };
    newShiftRotationDetail.orgId = organizationDetailState.getState().id;
    newShiftRotationDetail.staff_types = shiftDetail.staff_type_ids;
    newShiftRotationDetail.role_ids = shiftDetail.role_ids;
    newShiftRotationDetail.no_of_shift = 1;
    newShiftRotationDetail.notes = shiftDetail.shiftDetails.description;
    newShiftRotationDetail.department_ids = [shiftDetail.department_id];
    if (
      !!shiftDetail.sub_department_id &&
      shiftDetail.sub_department_id != EmptyGuid
    ) {
      newShiftRotationDetail.sub_department_ids = [
        shiftDetail.sub_department_id,
      ];
    } else {
      newShiftRotationDetail.sub_department_ids = [];
    }

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      // assignedStaffDetail.forEach((staffDetail: StaffDetail) => {

      // });
      newShiftRotationDetail.is_same_staff_member = true;
      newShiftRotationDetail.staff_id = assignedStaffDetail[0].id;
    } else {
      newShiftRotationDetail.staff_id = EmptyGuid;
    }

    let createdShiftDetails = await createRotationShift(newShiftRotationDetail);

    if (!!!createdShiftDetails) {
      await loadingIconState.setState(false);
      return;
    }

    props.handleCancelClick();
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);
    if (
      !!shiftDetail.custom_tag_name &&
      (!!!shiftDetail.custom_tag_id || shiftDetail.custom_tag_id <= 0)
    ) {
      await getCustomTagByOrgId(organizationDetailState.getState().id);
    }
    await loadingIconState.setState(false);
    setShiftDetail(emptyShift);
    setShiftRotation(emptyShiftRotation);
  };

  const getStaffDetailsByDeptId = async (
    staffTypeIds: any[],
    roleIds: any[]
  ) => {
    loadingIconState.setState(true);

    let locId = locationId != props.locationId ? locationId : props.locationId;
    let departId =
      shiftDetail.department_id != props.shiftDetail.department_id
        ? shiftDetail.department_id
        : props.shiftDetail.department_id;

    let subDepartId =
      shiftDetail.sub_department_id != props.shiftDetail.sub_department_id
        ? shiftDetail.sub_department_id
        : props.shiftDetail.sub_department_id;

    let searchCriteria: SearchStaffCriteria = {
      PageSize: 10,
      PageNumber: 0,
      isPaginationEnabled: false,
      org_id: organizationDetailState.getState().id,
      SortOptions: [],
      Filters: [],
    };

    if (!!roleIds && roleIds.length > 0) {
      searchCriteria.Filters.push({
        ColumnName: StaffFilterColumnEnum.RoleId,
        FilterValue: String(roleIds.join(",")),
        FilterType: StaffFilterOperatorEnum.In,
      });
    }

    if (!!staffTypeIds && staffTypeIds.length > 0) {
      searchCriteria.Filters.push({
        ColumnName: StaffFilterColumnEnum.StaffType,
        FilterValue: String(staffTypeIds[0]),
        FilterType: StaffFilterOperatorEnum.Equal,
      });
    }

    let staffList = await getStaffListBySearchCriteria(searchCriteria);
    let finalStaffList: StaffDetail[] = [];

    if (!!!staffList.results || staffList.results.length <= 0) {
      setFilteredStaffList([]);
      setNonFilteredStaffList([]);
      loadingIconState.setState(false);
      return;
    }

    if (!!staffTypeIds && staffTypeIds.length > 0 && staffTypeIds[0] == 103) {
      if (!!!staffList.results || staffList.results.length <= 0) {
        setFilteredStaffList([]);
        setNonFilteredStaffList([]);
        loadingIconState.setState(false);
        return;
      }

      let agencyMappedDetail = await getMappedAgency(
        organizationDetailState.getState().id
      );

      if (!!!agencyMappedDetail || agencyMappedDetail.length <= 0) {
        setFilteredStaffList([]);
        setNonFilteredStaffList([]);
        loadingIconState.setState(false);
        return;
      }

      let finalAgencyMappedDetail: AgencyDetail[] = [];

      if (!!locId) {
        let mappedDetail = agencyMappedDetail.filter((cc: AgencyDetail) => {
          if (
            !!!cc.agency_mapping_detail ||
            !!!cc.agency_mapping_detail.location_ids ||
            cc.agency_mapping_detail.location_ids.length <= 0
          ) {
            return cc;
          }

          if (
            !!cc.agency_mapping_detail &&
            !!cc.agency_mapping_detail.location_ids &&
            cc.agency_mapping_detail.location_ids.find(
              (loc: string) => loc == locId
            )
          ) {
            return cc;
          }
        });

        if (!!mappedDetail && mappedDetail.length > 0) {
          finalAgencyMappedDetail =
            finalAgencyMappedDetail.concat(mappedDetail);
        }
      }

      if (!!departId) {
        let mappedDetail = agencyMappedDetail.filter((cc: AgencyDetail) => {
          if (
            !!!cc.agency_mapping_detail ||
            !!!cc.agency_mapping_detail.department_ids ||
            cc.agency_mapping_detail.department_ids.length <= 0
          ) {
            return cc;
          }

          if (
            !!cc.agency_mapping_detail &&
            !!cc.agency_mapping_detail.department_ids &&
            cc.agency_mapping_detail.department_ids.find(
              (depart: string) => depart == departId
            )
          ) {
            return cc;
          }
        });

        if (!!mappedDetail && mappedDetail.length > 0) {
          finalAgencyMappedDetail =
            finalAgencyMappedDetail.concat(mappedDetail);
        }
      }

      if (!!!finalAgencyMappedDetail || finalAgencyMappedDetail.length <= 0) {
        setFilteredStaffList([]);
        setNonFilteredStaffList([]);
        loadingIconState.setState(false);
        return;
      }

      staffList.results.forEach((staffItem: StaffDetail) => {
        if (!!!staffItem.agencyDetails) {
          return;
        }

        if (!!staffItem.agencyDetails) {
          let findResult = finalAgencyMappedDetail.find(
            (agMapDetail: AgencyDetail) =>
              agMapDetail.id == staffItem.agencyDetails?.id
          );

          if (!!findResult) {
            finalStaffList.push(staffItem);
          }
        }
      });

      setFilteredStaffList(finalStaffList);
      setNonFilteredStaffList(finalStaffList);
      loadingIconState.setState(false);
      return;
    }

    staffList.results.forEach((staffDetail: StaffDetail) => {
      if (!!locId) {
        if (
          (!!!staffDetail.staffLocationMappings ||
            (!!staffDetail.staffLocationMappings &&
              staffDetail.staffLocationMappings.length <= 0)) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }

        if (
          !!staffDetail.staffLocationMappings &&
          staffDetail.staffLocationMappings.length > 0 &&
          staffDetail.staffLocationMappings.find(
            (staffLocMap: StaffLocationMappings) =>
              staffLocMap.location_id == locId
          ) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }
      }

      if (!!departId) {
        if (
          (!!!staffDetail.staffLocationMappings ||
            (!!staffDetail.staffLocationMappings &&
              staffDetail.staffLocationMappings.length <= 0)) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }

        if (
          !!staffDetail.staffLocationMappings &&
          staffDetail.staffLocationMappings.length > 0 &&
          staffDetail.staffLocationMappings.find(
            (staffLocMap: StaffLocationMappings) =>
              staffLocMap.department_id == departId
          ) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }
      }

      if (!!subDepartId) {
        if (
          (!!!staffDetail.staffLocationMappings ||
            (!!staffDetail.staffLocationMappings &&
              staffDetail.staffLocationMappings.length <= 0)) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }

        if (
          !!staffDetail.staffLocationMappings &&
          staffDetail.staffLocationMappings.length > 0 &&
          staffDetail.staffLocationMappings.find(
            (staffLocMap: StaffLocationMappings) =>
              staffLocMap.sub_department_id == subDepartId
          ) &&
          !finalStaffList.find(
            (staff: StaffDetail) => staff.id == staffDetail.id
          )
        ) {
          finalStaffList.push(staffDetail);
        }
      }
    });

    setFilteredStaffList(finalStaffList);
    setNonFilteredStaffList(finalStaffList);
    loadingIconState.setState(false);
  };

  const handleCancelShiftClick = async () => {
    loadingIconState.setState(true);
    await cancelShift({
      id: EmptyGuid,
      shift_id: shiftDetail.id,
      shift_status: shiftDetail.shift_status,
      reason: "",
      orgId: organizationDetailState.getState().id,
    });
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);
    loadingIconState.setState(false);
    props.handleCancelClick();
  };

  const handleDeleteShiftClick = async () => {
    loadingIconState.setState(true);
    await deleteShift(shiftDetail);
    let aShifts = await getAllShifts(searchShift);
    setAllShifts(aShifts);
    loadingIconState.setState(false);
    props.handleCancelClick();
  };

  const handleDepartmentChange = async (e: any) => {
    setShiftDetail({
      ...shiftDetail,
      department_id: e.target.value,
      sub_department_id: null,
    });

    if (!!!e.target.value || e.target.value == EmptyGuid) {
      setSubDepartmentList([]);
      return;
    }

    let unitList = await getSubDepartmentByDepartmentId(e.target.value);
    setSubDepartmentList(unitList);

    if (!!assignedStaffDetail && assignedStaffDetail.length > 0) {
      let finalList: StaffDetail[] = [];

      assignedStaffDetail.forEach((staffDetail: StaffDetail) => {
        if (!!staffDetail && staffDetail.staffLocationMappings.length > 0) {
          if (
            staffDetail.staffLocationMappings.filter(
              (dd) => dd.location_id == locationId
            ).length > 0
          ) {
            if (
              !!staffDetail.staffLocationMappings.filter(
                (dd) => dd.location_id == locationId
              )[0].department_id
            ) {
              if (
                staffDetail.staffLocationMappings.filter(
                  (cc) =>
                    cc.location_id == locationId &&
                    cc.department_id == e.target.value
                ).length > 0
              ) {
                finalList.push(staffDetail);
              }
            } else {
              finalList.push(staffDetail);
            }
          }
        } else {
          finalList.push(staffDetail);
        }
      });

      setAssignedStaffDetail(finalList);
    }
    setShiftDetail({ ...shiftDetail, department_id: e.target.value });
  };

  const getShiftTitle = (shiftStatus: number) => {
    if (shiftStatus === 1) {
      return "Assigned Shift";
    }

    if (shiftStatus === 2) {
      return "Open Shift";
    }

    if (shiftStatus === 4) {
      return "Cancelled Shift";
    }
  };

  const handleDeleteIconClick = () => {
    setshowDeleteConfirmationPopup(true);
  };

  const handleCloseClick = () => {
    setshowDeleteConfirmationPopup(false);
  };

  const handleCancelButtonClick = () => {
    setShowCancelConfirmationPopup(true);
  };

  const handleCloseCancelClick = () => {
    setShowCancelConfirmationPopup(false);
  };

  const handleDrawerCloseIconClick = () => {
    if(JSON.stringify(shiftDetail) != props.orginalShiftDetail) {
      setShowDiscardConfirmationPopup(true);
      return;
    }

    props.handleCancelClick();    
  };

  const handleCloseDiscardClick = () => {
    setShowDiscardConfirmationPopup(false);
  };

  const handleStaffChange = (e: any) => {
    if (e.target.value.find((ee: string) => ee == EmptyGuid)) {
      setAssignedStaffDetail([]);
      return;
    }

    if (!!e.target.value && e.target.value.length == 0) {
      setAssignedStaffDetail([]);
    }

    let selectedStaffList: StaffDetail[] = [];

    for (let i = 0; i < e.target.value.length; i++) {
      let sDetail = nonFilteredStaffList.find(
        (dd: StaffDetail) => dd.id == e.target.value[i]
      );

      if (!!sDetail) {
        selectedStaffList.push(sDetail);
      }
    }

    setAssignedStaffDetail(selectedStaffList);
  };

  const handleStaffRemove = (e: any) => {
    let selectedStaffList = assignedStaffDetail.filter((cc) => cc.id != e);

    if (!!!selectedStaffList || selectedStaffList.length <= 0) {
      setAssignedStaffDetail([]);
      return;
    }

    setAssignedStaffDetail(selectedStaffList);
  };

  const getRoleName = (roleIds: number[]) => {
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  const getSelectedStaffLableValue = (staffDetailId: string) => {
    if (
      !!nonFilteredStaffList &&
      nonFilteredStaffList.length > 0 &&
      !!nonFilteredStaffList.find((cc) => cc.id == staffDetailId)
    ) {
      let sDetail = nonFilteredStaffList.find((cc) => cc.id == staffDetailId);

      if (!!sDetail) {
        return `${sDetail.first_name} ${sDetail.last_name}`;
      } else {
        return "";
      }
    }
    return "";
  };

  const getSelectedStaffRoleDetail = (staffDetailId: string) => {
    if (
      !!nonFilteredStaffList &&
      nonFilteredStaffList.length > 0 &&
      !!nonFilteredStaffList.find((cc) => cc.id == staffDetailId)
    ) {
      let sDetail = nonFilteredStaffList.find((cc) => cc.id == staffDetailId);

      if (!!sDetail) {
        return getRoleName(sDetail.role_ids);
      } else {
        return "";
      }
    }
    return "";
  };

  const handleCustomTagChange = async (event: any, value: any) => {
    setShiftDetail({
      ...shiftDetail,
      custom_tag_name: event.target.value,
      custom_tag_id: 0,
    });
  };

  const handleCustomTagAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setShiftDetail({
        ...shiftDetail,
        custom_tag_name: value.name,
        custom_tag_id: value.id,
      });

      return;
    }
  };

  const handleSearchAssignee = (event: any) => {
    setSearchAssignee(event.target.value);
    let sList = nonFilteredStaffList.slice();

    if (!!sList && sList.length > 0 && !!event.target.value) {
      let filteredValue = sList.filter((dd: StaffDetail) => {
        let name = `${dd.first_name} ${dd.last_name}`;
        if (
          name
            .toLocaleLowerCase()
            .indexOf(event.target.value.toLocaleLowerCase()) >= 0
        ) {
          return dd;
        }
      });
      setFilteredStaffList(filteredValue);
    } else {
      setFilteredStaffList(sList);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "99%",
          height: "99%",
          border: "1px solid #E3E5E8",
          background: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          boxShadow: "-2px 0px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* header container */}
          <Box
            sx={{
              width: "calc(100% - 32px)",
              height: "7%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "92%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyItem: "center",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "24.3px",
                  color: "#17191C",
                }}
              >
                {!!shiftDetail.id && shiftDetail.id != EmptyGuid
                  ? getShiftTitle(shiftDetail.shift_status)
                  : "Shift details"}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "24.3px",
                  color: "#737F8C",
                }}
              >
                5275624736481
              </Typography> */}
            </Box>
            <Box
              sx={{
                width: "8%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleDrawerCloseIconClick}
            >
              <CloseIcon
                sx={{ width: "24px", height: "24px", color: "#0D80F2" }}
              />
            </Box>
          </Box>
          {/* body container */}

          <OverFlowBox
            sx={{
              width: "calc(100% - 48px)",
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "95%",
                height: "76px",
                display: "flex",
                background: "#E7F2FE",
                borderRadius: "12px",
                padding: "6px 16px",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px 0px",
                  gap: "4px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "#031A30",
                    }}
                  >
                    Do you want to start from monthly planning?
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20.02px",
                      letterSpacing: "0.17px",
                      color: "#031A30",
                    }}
                  >
                    Description
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  height: "34px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "start",
                  padding: "10px 0px 0px 16px",
                  // cursor: "pointer",
                  gap: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "0.46px",
                    //color: "#0D80F2",
                    color:"#ABB2BA"
                  }}
                >
                  Monthly planning
                </Typography>
                <Box
                  sx={{
                    paddingTop: "1%",
                  }}
                >
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 3L7.9425 4.0575L12.1275 8.25H3V9.75H12.1275L7.9425 13.9425L9 15L15 9L9 3Z"
                      // fill="#0D80F2"
                      fill="#ABB2BA"
                    />
                  </svg>
                </Box>
              </Box>
            </Box>

            {/* shift configuration */}
            <Box
              sx={{
                width: "calc(100% - 32px)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #F1F2F4",
                padding: "16px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#17191C",
                    lineHeight: "24.3px",
                  }}
                >
                  Shift configuration
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography
                      text="Shift template"
                      required={false}
                    />
                  </LableTextFieldBox>
                  <CalendarDropDown
                    sx={{
                      ".MuiInputBase-input": {
                        color: "#6EB2F7  !important",
                      },
                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA",
                        },
                      },
                    }}
                    handleChange={function (e: any): void {}}
                    value={1}
                    disabled={true}
                  >
                    <MenuItem value={1}> Select shift template </MenuItem>
                  </CalendarDropDown>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    color: "#737F8C",
                    justifyContent: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "4px",
                    }}
                  >
                    <LableTextFieldBox>
                      <TextFieldLableTypography
                        text="Number of shifts"
                        required={false}
                      />
                    </LableTextFieldBox>
                    <Box
                      sx={{
                        paddingTop: "1%",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                          fill="#737F8C"
                        />
                      </svg>
                    </Box>
                  </Box>
                  <NumberInputField
                    value={shiftDetail.number_of_shifts}
                    disabled={
                      (!!shiftDetail && shiftDetail.id != EmptyGuid
                        ? true
                        : false) || shiftType != 1 ? true : false
                    }
                    handleChange={(event, number) =>
                      number != null
                        ? setShiftDetail({
                            ...shiftDetail,
                            number_of_shifts: number,
                          })
                        : setShiftDetail({
                            ...shiftDetail,
                            number_of_shifts: 1,
                          })
                    }
                  />
                </Box>
              </Box>
            </Box>

            {/* staff form */}
            {props.addShiftSelection == 1 && (
              <CalendarTalentForm
                agencyName={agencyName}
                assignedStaffDetail={assignedStaffDetail}
                getSelectedStaffLableValue={getSelectedStaffLableValue}
                getSelectedStaffRoleDetail={getSelectedStaffRoleDetail}
                handleRoleChange={handleRoleChange}
                handleRoleRemove={handleRoleRemove}
                handleSearchAssignee={handleSearchAssignee}
                handleSelectStaffClick={handleSelectStaffClick}
                handleStaffChange={handleStaffChange}
                handleStaffRemove={handleStaffRemove}
                orgRoleList={orgRoleList}
                searchAssignee={searchAssignee}
                selectedStaffIds={selectedStaffIds}
                shiftDetail={shiftDetail}
                filteredStaffList={filteredStaffList}
                nonFilteredStaffList={nonFilteredStaffList}
                staffTypeList={staffTypeList}
              />
            )}

            {/* Shift Type form */}
            <Box
              sx={{
                width: "calc(100% - 32px)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #F1F2F4",
                padding: "16px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "98%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#17191C",
                    lineHeight: "24.3px",
                  }}
                >
                  Shift type
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography
                      text="Reccurence"
                      required={false}
                    />
                  </LableTextFieldBox>
                  <CalendarDropDown
                    sx={{
                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA !important",
                        },
                      },
                    }}
                    value={shiftType}
                    handleChange={(e: any) => {
                      setShiftType(e.target.value);
                    }}
                  >
                    <MenuItem value={0} selected>
                      <Typography16_400
                        text={"Select recurrence type"}
                        color="#6EB2F7"
                      />
                    </MenuItem>
                    <MenuItem value={1}>
                      <Typography16_400
                        text={"Doesn’t repeat"}
                        color="#031A30"
                      />
                    </MenuItem>
                    <MenuItem value={2}>
                      <Typography16_400 text={"Series"} color="#031A30" />
                    </MenuItem>
                    <MenuItem value={3}>
                      <Typography16_400 text={"Rotation"} color="#031A30" />
                    </MenuItem>
                  </CalendarDropDown>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    color: "#737F8C",
                    justifyContent: "start",
                  }}
                >
                  <LableTextFieldBox>
                    {shiftType == 2 || shiftType == 3 ? (
                      <TextFieldLableTypography
                        text="Shift preset"
                        required={false}
                      />
                    ) : (
                      <TextFieldLableTypography
                        text="Recurrence preset"
                        required={false}
                      />
                    )}
                  </LableTextFieldBox>
                  <CalendarDropDown
                    //disabled={shiftType == 1 ? true : false}
                    disabled
                    sx={{
                      ".MuiInputBase-input": {
                        color: "#6EB2F7  !important",
                      },

                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA !important",
                        },
                      },
                    }}
                    handleChange={function (e: any): void {}}
                    value={1}
                  >
                    {shiftType == 2 || shiftType == 3 ? (
                      <MenuItem value={1}> Select shift preset </MenuItem>
                    ) : (
                      <MenuItem value={1}> Select recurrence preset </MenuItem>
                    )}
                  </CalendarDropDown>
                </Box>
              </Box>
            </Box>
            {/* Date & Time Form */}

            {shiftType == 0 && (
              <Box
                sx={{
                  width: "calc(100% - 32px)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "1px solid #F1F2F4",
                  padding: "16px",
                  gap: "16px",
                }}
              >
                <Box
                  sx={{
                    width: "98%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#17191C",
                      lineHeight: "24.3px",
                    }}
                  >
                    Date and duration
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "98%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#737F8C",
                      lineHeight: "24px",
                    }}
                  >
                    Please, select shift recurrence type first
                  </Typography>
                </Box>
              </Box>
            )}

            {shiftType == 1 && (
              <ShiftDetailContext.Provider
                value={{ shiftDetail, setShiftDetail }}
              >
                <CalendarSingleShiftDateForm
                  shiftDate={props.shiftDate}
                  shiftStartTime={shiftStartTime}
                  shiftEndTime={shiftEndTime}
                />
              </ShiftDetailContext.Provider>
            )}
            {shiftType == 2 && (
              <ShiftSeriesContext.Provider
                value={{ shiftSeries, setShiftSeries }}
              >
                <CalendarSeriesShiftDateForm />
              </ShiftSeriesContext.Provider>
            )}
            {shiftType == 3 && (
              <ShiftRoationContext.Provider
                value={{ shiftRotation, setShiftRotation }}
              >
                <CalendarRotationShiftDateForm />
              </ShiftRoationContext.Provider>
            )}

            {/* Location Form */}
            <Box
              sx={{
                width: "calc(100% - 32px)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #F1F2F4",
                padding: "16px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "98%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#17191C",
                    lineHeight: "24.3px",
                  }}
                >
                  Location
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    color: "#737F8C",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography text="Location" required={true} />
                  </LableTextFieldBox>
                  <CalendarDropDown
                    sx={{
                      ".MuiInputBase-input": {
                        color: "#6EB2F7  !important",
                      },

                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA !important",
                        },
                      },
                    }}
                    handleChange={handleLocationChange}
                    value={locationId}
                    placeholder="Select location"
                  >
                    <MenuItem value={EmptyGuid}> Select location </MenuItem>
                    {locationList.map((cc: LocationDetail) => (
                      <MenuItem value={cc.id} key={cc.id}>
                        <Typography sx={{ color: "#031A30" }}>
                          {cc.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </CalendarDropDown>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    color: "#737F8C",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography
                      text="Department"
                      required={true}
                    />
                  </LableTextFieldBox>
                  <CalendarDropDown
                    sx={{
                      ".MuiInputBase-input": {
                        color: "#6EB2F7  !important",
                      },

                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA !important",
                        },
                      },
                    }}
                    handleChange={handleDepartmentChange}
                    value={shiftDetail.department_id}
                  >
                    <MenuItem value={EmptyGuid}> Select department </MenuItem>
                    {departmentList.map((cc: DepartmentDetail) => (
                      <MenuItem value={cc.id} key={cc.id}>
                        <Typography sx={{ color: "#031A30" }}>
                          {cc.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </CalendarDropDown>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    color: "#737F8C",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography
                      text="Sub-department"
                      required={false}
                    />
                  </LableTextFieldBox>
                  <CalendarDropDown
                    sx={{
                      ".MuiInputBase-input": {
                        color: "#6EB2F7  !important",
                      },

                      "&.Mui-disabled": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #ABB2BA !important",
                        },
                      },
                    }}
                    handleChange={(e: any) => {
                      setShiftDetail({
                        ...shiftDetail,
                        sub_department_id: e.target.value,
                      });
                    }}
                    value={
                      !!shiftDetail.sub_department_id
                        ? shiftDetail.sub_department_id
                        : EmptyGuid
                    }
                  >
                    <MenuItem value={EmptyGuid}>Select sub-department</MenuItem>
                    {subDepartmentList.map((cc: UnitDetail) => (
                      <MenuItem value={cc.id} key={cc.id}>
                        <Typography sx={{ color: "#031A30" }}>
                          {cc.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </CalendarDropDown>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  color: "#737F8C",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LableTextFieldBox>
                  <TextFieldLableTypography text="Specialty" required={false} />
                </LableTextFieldBox>
                <PlatformTextField
                  sx={{
                    width: "100%",
                    "& fieldset": {
                      border: "1px solid #F1F2F4 !important",
                      borderRadius: "8px",
                    },
                    ".MuiInputBase-root .MuiOutlinedInput-input": {
                      backgroundColor: "#F1F2F4 !important",
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={"Select the department to see the specialties applied"}
                  handleChange={function (e: any): void {}}
                  placeholder={""}
                />
              </Box>
            </Box>

            {props.addShiftSelection == 2 && (
              <CalendarTalentForm
                agencyName={agencyName}
                assignedStaffDetail={assignedStaffDetail}
                getSelectedStaffLableValue={getSelectedStaffLableValue}
                getSelectedStaffRoleDetail={getSelectedStaffRoleDetail}
                handleRoleChange={handleRoleChange}
                handleRoleRemove={handleRoleRemove}
                handleSearchAssignee={handleSearchAssignee}
                handleSelectStaffClick={handleSelectStaffClick}
                handleStaffChange={handleStaffChange}
                handleStaffRemove={handleStaffRemove}
                orgRoleList={orgRoleList}
                searchAssignee={searchAssignee}
                selectedStaffIds={selectedStaffIds}
                shiftDetail={shiftDetail}
                filteredStaffList={filteredStaffList}
                nonFilteredStaffList={nonFilteredStaffList}
                staffTypeList={staffTypeList}
              />
            )}

            {/* notes */}
            <Box
              sx={{
                width: "calc(100% - 32px)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                border: "1px solid #F1F2F4",
                padding: "16px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "98%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#17191C",
                    lineHeight: "24.3px",
                  }}
                >
                  Additional information
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    color: "#737F8C",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LableTextFieldBox>
                    <TextFieldLableTypography text="Tag" required={false} />
                  </LableTextFieldBox>
                  <AutoCompleteTextInput
                    onInputChange={(e: any, value: any) => {
                      handleCustomTagChange(e, value);
                    }}
                    onChange={handleCustomTagAutocompleteChange}
                    options={props.customTagList}
                    getOptionLabel={(option: any) =>
                      typeof option === "string" ? option : option.name
                    }
                    label={""}
                    placeholder={"Apply a tag"}
                    freeSolo={true}
                    sx={{ width: "100%" }}
                    value={
                      !!props.customTagList &&
                      props.customTagList.length > 0 &&
                      !!shiftDetail &&
                      shiftDetail.id != EmptyGuid &&
                      !!shiftDetail.custom_tag_id &&
                      shiftDetail.custom_tag_id > 0
                        ? !!props.customTagList.find(
                            (tag) => tag.id == shiftDetail.custom_tag_id
                          )
                          ? props.customTagList.find(
                              (tag) => tag.id == shiftDetail.custom_tag_id
                            )
                          : ""
                        : ""
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  color: "#737F8C",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <LableTextFieldBox>
                  <TextFieldLableTypography text="Note" required={false} />
                </LableTextFieldBox>
                <CalendarTextAreaField
                  Label={"Add anything important"}
                  value={shiftDetail.shiftDetails.description}
                  handleChange={(e: any) => {
                    setShiftDetail({
                      ...shiftDetail,
                      shiftDetails: {
                        ...shiftDetail.shiftDetails,
                        description: e.target.value,
                      },
                    });
                  }}
                />
              </Box>
            </Box>
          </OverFlowBox>
          {/* Button Container */}
          <Box
            sx={{
              width: "calc(100% - 32px)",
              height: "10%",
              display: "flex",
              flexDirection: "row",
              border: "1px solid #E3E5E8",
              alignItems: "center",
              padding: "16px",
            }}
          >
            {/* <Typography
              sx={{
                padding: "0 1% 0 3%",
                fontSize: "16px",
                fontWeight:"500",
                color:"#17191C"
              }}
            >
              Copies
            </Typography>
            <Box
              sx={{
                width: "8%",
              }}
            >
              <BorderedTextField value={"1"} handleChange={(e: any) => {}} />
            </Box> */}
            <Box
              sx={{
                width: "93%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyContent: "right",
                paddingRight: "1%",
              }}
            >
              <OutlinedButton
                disabled={
                  !!shiftDetail &&
                  shiftDetail.id != EmptyGuid &&
                  shiftDetail.shift_status == 1
                    ? false
                    : true
                }
                text={"Cancel"}
                handleClick={handleCancelButtonClick}
                sx={{
                  height: "40px",
                  color: "#F2460D !important",
                  borderColor: "#F2460D !important",
                  borderRadius: "8px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              />

              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "40px",
                  minWidth: "40px !important",
                  background: "#F2460D",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#FFFFFF !important",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  boxShadow: "none !important",
                  ":disabled": {
                    backgroundColor: "#ABB2BA",
                  },
                  ":hover": {
                    background: "#F2460D !important",
                  },
                }}
                disabled={
                  !!shiftDetail &&
                  shiftDetail.id != EmptyGuid &&
                  shiftDetail.shift_status == 2
                    ? false
                    : true
                }
                onClick={handleDeleteIconClick}
              >
                <svg
                  width="16"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.19922 1.74922C4.19922 1.30739 4.55739 0.949219 4.99922 0.949219H10.9992C11.441 0.949219 11.7992 1.30739 11.7992 1.74922C11.7992 2.19105 11.441 2.54922 10.9992 2.54922H4.99922C4.55739 2.54922 4.19922 2.19105 4.19922 1.74922ZM0.199219 4.99922C0.199219 4.55739 0.557391 4.19922 0.999219 4.19922H14.9992C15.441 4.19922 15.7992 4.55739 15.7992 4.99922C15.7992 5.44105 15.441 5.79922 14.9992 5.79922H14.7992V15.9992C14.7992 17.546 13.546 18.7992 11.9992 18.7992H3.99922C2.45239 18.7992 1.19922 17.546 1.19922 15.9992V5.79922H0.999219C0.557391 5.79922 0.199219 5.44105 0.199219 4.99922ZM2.79922 5.79922V15.9992C2.79922 16.6624 3.33605 17.1992 3.99922 17.1992H11.9992C12.6624 17.1992 13.1992 16.6624 13.1992 15.9992V5.79922H2.79922ZM5.99922 8.19922C6.44105 8.19922 6.79922 8.55739 6.79922 8.99922V13.9992C6.79922 14.441 6.44105 14.7992 5.99922 14.7992C5.55739 14.7992 5.19922 14.441 5.19922 13.9992V8.99922C5.19922 8.55739 5.55739 8.19922 5.99922 8.19922ZM9.99922 8.19922C10.441 8.19922 10.7992 8.55739 10.7992 8.99922V13.9992C10.7992 14.441 10.441 14.7992 9.99922 14.7992C9.55739 14.7992 9.19922 14.441 9.19922 13.9992V8.99922C9.19922 8.55739 9.55739 8.19922 9.99922 8.19922Z"
                    fill="white"
                  />
                </svg>
              </Button>

              <OutlinedButton
                disabled={
                  !!!shiftDetail ||
                  shiftDetail.id == EmptyGuid ||
                  (!!shiftDetail &&
                    shiftDetail.id != EmptyGuid)
                    ? false
                    : true
                }
                text={"Save"}
                handleClick={handleAddShiftClick}
                sx={{ height: "40px", width: "67px" }}
              />
              <ContainedButton
                disabled={true}
                text={"Publish"}
                sx={null}
                handleClick={handlePublishShiftClick}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <DeleteConfirmationPopup
        title={"Delete the shift"}
        content={
          "You are going to delete the shift. Assigned staff will be notified about that. Do you want to delete the shift?"
        }
        open={showDeleteConfirmationPopup}
        handleClick={handleCloseClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            gap: "16px",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px", width: "68px" }}
            text="Back"
            handleClick={handleCloseClick}
          />
          <ContainedDeleteButton
            sx={{ height: "40px", width: "78px" }}
            text="Delete"
            handleClick={handleDeleteShiftClick}
          />
        </Box>
      </DeleteConfirmationPopup>

      <DeleteConfirmationPopup
        title={"Cancel the shift"}
        content={
          "You are going to cancel the shift. If the shift was assigned, the employee(s) will be notified about cancellation. Do you want to proceed?'"
        }
        open={showCancelConfirmationPopup}
        handleClick={handleCloseCancelClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            gap: "16px",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px", width: "68px" }}
            text="Back"
            handleClick={handleCloseCancelClick}
          />
          <ContainedDeleteButton
            sx={{ height: "40px", width: "82px" }}
            text="Cancel"
            handleClick={handleCancelShiftClick}
          />
        </Box>
      </DeleteConfirmationPopup>

      <DeleteConfirmationPopup
        title={"Unsaved changes"}
        content={
          "Do you want to leave shift editing? All the unsaved changes will be discarded. Do you want to leave without saving?"
        }
        open={showDiscardConfirmationPopup}
        handleClick={handleCloseDiscardClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            gap: "16px",
          }}
        >
          <OutlinedButton
            sx={{ height: "40px", width: "68px" }}
            text="Back"
            handleClick={handleCloseDiscardClick}
          />
          <ContainedDeleteButton
            sx={{ height: "40px" }}
            text="Discard changes"
            handleClick={props.handleCancelClick}
          />
        </Box>
      </DeleteConfirmationPopup>
    </>
  );
};

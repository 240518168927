import { Box } from "@mui/material";
import {
  LocationDepartmentIcon,
  OrganizationIcon,
  OverFlowBox,
  PermissionsIcon,
  RolesIcon,
  SettingNavigationBox,
  SettingNavigationBoxWithoutHover,
  StaffIcon,
  StaffPresetsIcon,
  StaffingPartnersIcon,
  Typography14_600,
  UsersIcon,
} from "../atom";
import {
  LocationSetting,
  OrganisationSetting,
  RolesSetting,
} from "../organism";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  currentActiveScreenState,
} from "../../libs/state-management-lib";
import { StaffSetting } from "../organism/staff/staffSetting";
import { StaffingPartnerSetting } from "../organism/staffingPartner/staffingPartnerSetting";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserDetail } from "../../libs/services-lib";
import { SettingNavigation } from "../molecule";

export const SettingsTemplate = () => {

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SettingNavigation selectedMenu="Organization" />
      <Box
        sx={{
          width: "85%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <OrganisationSetting />
      </Box>
    </Box>
  );
};

import { Box, Typography } from "@mui/material";
import { AddIcon, CollapseIcon } from "../../icons/icon";
import { LocationDepartmentDetail } from "../../../../libs/model-lib";
import { useState } from "react";

interface ICalendarDepartNameBox {
  locationDepartDetail: LocationDepartmentDetail;
  handleCollapseClick: (expandItem: boolean) => void;
}

export const CalendarDepartNameBox = (props: ICalendarDepartNameBox) => {
  const [expandItem, setExpandItem] = useState(true);

  const handleDepartmentCollapseClick = (departId: string) => {
    setExpandItem(!expandItem);
    props.handleCollapseClick(!expandItem);
  }

  return (
    <>
      <Box
        sx={{
          width: "16%",
          display: "flex",
          flexDirection: "column",
          height: "100%",          
          borderTop:"1px solid #CFE5FC"
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 16px)",
            display: "flex",
            flexDirection: "row",
            background: "#FFFFFF",
            padding: "16px 8px",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box
            onClick={() => {handleDepartmentCollapseClick(props.locationDepartDetail.department_id)}}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            {expandItem && <CollapseIcon sx={{ color: "#0D80F2" }} />}
            {!expandItem && (
              <CollapseIcon
                sx={{ color: "#0D80F2", transform: "rotate(180deg)" }}
              />
            )}
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "22.4px",
              color: "#737F8C",
            }}
          >
            {props.locationDepartDetail.department_name}
          </Typography>
          <AddIcon sx={{ 
            // color: "#0D80F2", 
            // cursor: "pointer",
            color:"#ABB2BA"
             }} />
        </Box>
      </Box>
    </>
  );
};

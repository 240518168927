import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DetailSettingBox } from "../../organism";
import {
  Decryption,
  EmptyGuid,
  emptyStaffDetail,
} from "../../../libs/common-lib";
import {
  currentActiveScreenState,
  loadingIconState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import {
  createStaff,
  getStaffById,
  updateStaff,
} from "../../../libs/services-lib";
import { StaffDetail } from "../../../libs/model-lib";
import Joi from "joi";
import { format } from "date-fns";
import { StaffDetailContext } from "../../../libs/context-lib/staffDetailContext";
import { StaffDetailForm } from "../../organism/staff/staffDetailForm";
import { StaffInfo } from "../../organism/staff/staffInfo";

const StaffDetailTemplate = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const [staffDetail, setStaffDetail] = useState<StaffDetail>(emptyStaffDetail);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (!!id) {
        if (Decryption(id) == EmptyGuid) {
          setStaffDetail(emptyStaffDetail);
        } else {
          loadingIconState.setState(true);
          let sDetail = await getStaffById(Decryption(id));
          setStaffDetail(sDetail);
          loadingIconState.setState(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const schema = Joi.object<StaffDetail>({
    id: Joi.string().allow(""),
    agency_id: Joi.string().allow("").allow(null),
    org_id: Joi.string().allow(""),
    staff_type: Joi.number().allow(0),
    first_name: Joi.string().required().max(255).messages({
      "string.empty": `First name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    last_name: Joi.string().required().max(255).messages({
      "string.empty": `Last name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    mobile: Joi.alternatives().conditional("staff_type", {
      is: 102,
      then: Joi.string().min(17).required().messages({
        "string.empty": `Phone number cannot be an empty field`,
        "string.min": `Enter valid phone number`,
      }),
      otherwise: Joi.string().min(17).allow("").messages({
        "string.min": `Enter valid phone number`,
      }),
    }),
    email: Joi.alternatives().conditional("staff_type", {
      is: 102,
      then: Joi.string().required().max(255).email({ tlds: false }).messages({
        "string.empty": `Email cannot be an empty field`,
        "string.email": `Email is not valid`,
        "string.max": `The maximum character limit should be 255`,
      }),
      otherwise: Joi.string()
        .allow("")
        .max(255)
        .email({ tlds: false })
        .messages({
          "string.email": `Email is not valid`,
          "string.max": `The maximum character limit should be 255`,
        }),
    }),
    address_1: Joi.string().required().max(255).messages({
      "string.empty": `Address 1 cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    address_2: Joi.string().allow("").max(255).messages({
      "string.max": `The maximum character limit should be 255`,
    }),
    city: Joi.string().allow("").max(100).messages({
      "string.max": `The maximum character limit should be 100`,
    }),
    state: Joi.string().allow("").max(100).messages({
      "string.max": `The maximum character limit should be 100`,
    }),
    zipcode: Joi.string().min(5).max(5).allow("").allow(null).messages({
      "string.min": `Postal code is invalid`,
      "string.max": `Postal code is invalid`,
    }),
    ssn: Joi.string().min(11).max(11).allow("").messages({
      "string.min": `Invalid SSN`,
      "string.max": `Invalid SSN`,
    }),
    npi: Joi.string().min(10).max(10).allow("").messages({
      "string.min": `NPI should be 10 digit`,
      "string.max": `NPI should be 10 digit`,
    }),
    date_of_birth: Joi.string().allow("").allow(null),
    hourly_pay_rate: Joi.number().allow(0, "").optional(),
    overtime_pay_rate: Joi.number().allow(0, "").optional(),
    contracted_hours: Joi.number().allow(0, "").optional(),
    role_ids: Joi.array().min(1).required().messages({
      "array.min": `Select role type`,
    }),
    created_on: Joi.date(),
    is_active: Joi.boolean(),
    staffLocationMappings: Joi.array().empty(Joi.array().length(0)),
    staffWorkingHours: Joi.number().allow(null),
    agencyDetails: Joi.object().allow(null),
  }).options({ abortEarly: false });

  const handleStaffSaveClick = async () => {
    const result = schema.validate(staffDetail);
    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    let sDetail = { ...staffDetail };

    if (!!sDetail.id && sDetail.id != EmptyGuid) {
      sDetail.org_id = organizationDetailState.getState().id;
      let hourly_pay_rate = Number(sDetail.hourly_pay_rate);
      let overtime_pay_rate = Number(sDetail.overtime_pay_rate);
      let contracted_hours = Number(sDetail.contracted_hours);
      sDetail.hourly_pay_rate = !!hourly_pay_rate ? hourly_pay_rate : 0;
      sDetail.overtime_pay_rate = !!overtime_pay_rate ? overtime_pay_rate : 0;
      sDetail.contracted_hours = !!contracted_hours ? contracted_hours : 0;
      sDetail.created_on = new Date();
      sDetail.date_of_birth = sDetail.date_of_birth;
      try {
        await updateStaff(sDetail);
      } catch (error) {}
    } else {
      sDetail.agency_id = sDetail.agency_id;
      sDetail.id = EmptyGuid;
      sDetail.org_id = organizationDetailState.getState().id;
      let hourly_pay_rate = Number(sDetail.hourly_pay_rate);
      let overtime_pay_rate = Number(sDetail.overtime_pay_rate);
      let contracted_hours = Number(sDetail.contracted_hours);
      sDetail.hourly_pay_rate = !!hourly_pay_rate ? hourly_pay_rate : 0;
      sDetail.overtime_pay_rate = !!overtime_pay_rate ? overtime_pay_rate : 0;
      sDetail.contracted_hours = !!contracted_hours ? contracted_hours : 0;
      sDetail.created_on = new Date();
      try {
        await createStaff(sDetail);
        setStaffDetail(emptyStaffDetail);
      } catch (error) {}
    }
    loadingIconState.setState(false);
  };

  const handleStaffCancelClick = () => {
    navigate("/settings/talents");
  };

  return (
    <DetailSettingBox
      navigationSelectedMenu="Talents"
      playBoxTitle="Setting up talents"
      playBoxDuration="1 min."
      pageTitle={
        !!id && Decryption(id) == EmptyGuid ? "Add talent" : "Update talent"
      }
      handleSaveClick={handleStaffSaveClick}
      handleCancelClick={handleStaffCancelClick}
    >
      <StaffDetailContext.Provider value={{ staffDetail, setStaffDetail }}>
        <StaffDetailForm errorMessage={errorMessage} />
        <StaffInfo errorMessage={errorMessage} />
      </StaffDetailContext.Provider>
    </DetailSettingBox>
  );
};

export default StaffDetailTemplate;

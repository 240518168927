import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { currentActiveScreenState, loadingIconState } from "../../libs/state-management-lib";
import { SignInSetting } from "../organism";
import { OverFlowBox } from "../atom";

export const SignInTemplate = () => {
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  useLayoutEffect(() => {
    const loadIconStateSub = loadingIconState.subscribe(async (state) => {
      setShowLoadingIcon(state);
    });

    return () => {
      loadIconStateSub();
    };
  }, []);
  
  return (
    <>
      {showLoadingIcon && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingIconState.getState()}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box className="signup-main-container-col-holder">
        <Box className="signup-main-container-col-1">
          <OverFlowBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "24px",
              height: "calc(100% - 10px)",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Box className="signup-main-container">
              <SignInSetting />
            </Box>
          </OverFlowBox>
        </Box>
        <Box className="signup-main-container-col-2">
          <Box className="signup-main-container-col-2-bg-img"></Box>
        </Box>
      </Box>
    </>
  );
};

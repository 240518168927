import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemButton,
  Menu,
  Switch,
  Typography,
} from "@mui/material";
import {
  ActiveConfirmationPopup,
  ActiveIcon,
  ContainedButton,
  ContainedDeleteButton,
  ContractorIcon,
  DeleteIcon,
  InActiveConfirmationPopup,
  OrganizationIcon,
  OutlinedButton,
  Typography14_400,
  Typography14_600,
  Typography16_400,
  Typography16_600,
  Typography32_600,
} from "../../atom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { SetStateAction, useEffect, useLayoutEffect, useState } from "react";
import { staffDetailListState } from "../../../libs/state-management-lib/staffState";
import { useShallow } from "zustand/react/shallow";
import {
  activeConfirmationPopupState,
  confirmationPopupTitleState,
  inActiveConfirmationPopupState,
  orgRoleListState,
  staffTypeListState,
} from "../../../libs/state-management-lib";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  DepartmentDetail,
  GenericMasterData,
  OrgRole,
  StaffDetail,
} from "../../../libs/model-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { any } from "joi";
import { IosSwitch } from "../../atom/Switch/iosSwitch";
import { getStaffDetailList } from "../../../libs/services-lib";
import { AgencyDetail } from "../../../libs/model-lib/agencyDetail";
import { stringAvatar } from "../../../libs/common-lib";

interface IStaffDashboard {
  handleStaffDashboardCallBack: (staffId: string) => void;
  handleActiveStafft: (activeStaffId: string) => void;
  handleInActiveStafft: (inActiveStaffId: string) => void;
  handleAddStaff: () => void;
}

export const StaffDashboard = (props: IStaffDashboard) => {
  const [staffDetailList, setStaffDetailList] = useState<StaffDetail[]>(staffDetailListState.getState().staffDetailList
  );
  const [rowData, setRowData] = useState<any>([]);
  const [menuState, setMenuState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [activeChecked, setActiveChecked] = useState<boolean>(false);
  const [activeStaffId, setActiveStaffId] = useState("");
  const [showActiveConfirmationPopup, setshowActiveConfirmationPopup] =
    useState(false);
  const [showInActiveConfirmationPopup, setshowInActiveConfirmationPopup] =
    useState(false);

  const [orgRoleList, setOrgRoleList] = useState<OrgRole[]>(
    orgRoleListState.getState().orgRoleList
  );

  const [staffTypeList, setStaffTypeList] = useState<GenericMasterData[]>(
    staffTypeListState.getState().staffTypeList
  );

  const [rows, setRows] = useState<any[]>([]);

  useLayoutEffect(() => {
    const staffDetailListStateSub = staffDetailListState.subscribe(
      async (state) => {
        setStaffDetailList(state.staffDetailList);
        if (!!state.staffDetailList && state.staffDetailList.length > 0) {
          setRows(
            state.staffDetailList.map((cc) => ({
              id: cc.id,
              name: cc.first_name + " " + cc.last_name,
              email: cc.email,
              phone: cc.mobile,
              role: cc.role_ids,
              staffType: cc.staff_type,
              status: cc.is_active,
              agencyDetail: cc.agencyDetails,
            }))
          );
        }
      }
    );

    const inActiveConfirmationPopupStateSub =
      inActiveConfirmationPopupState.subscribe(async (state) => {
        setshowInActiveConfirmationPopup(state);
      });

    const activeConfirmationPopupStateSub =
      activeConfirmationPopupState.subscribe(async (state) => {
        setshowActiveConfirmationPopup(state);
      });

    return () => {
      staffDetailListStateSub();
      inActiveConfirmationPopupStateSub();
      activeConfirmationPopupStateSub();
    };
  }, []);

  const refreshEvent = () => {
    const modifiedData = !!staffDetailList
      ? staffDetailList.map((cc) => ({
          id: cc.id,
          name: cc.first_name + " " + cc.last_name,
          email: cc.email,
          phone: cc.mobile,
          role: cc.role_ids,
          staffType: cc.staff_type,
          status: cc.is_active == true ? "Active" : "InActive",
          agencyDetail: cc.agencyDetails,
        }))
      : [];
    setRowData(modifiedData);
  };

  // const rows = !!staffDetailList
  //   ? staffDetailList.map((cc) => ({
  //       id: cc.id,
  //       name: cc.first_name + " " + cc.last_name,
  //       email: cc.email,
  //       phone: cc.mobile,
  //       role: cc.role_ids,
  //       staffType: cc.staff_type,
  //       status: cc.is_active,
  //       agencyDetail: cc.agencyDetails,
  //     }))
  //   : [];

  const handleEditStaffClick = async (staffId: any) => {
    props.handleStaffDashboardCallBack(staffId.id);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, params: any) => {
    setMenuState({ anchorEl: event.currentTarget, rowId: params.id });
    setActiveChecked(params.row.status);
  };

  const handleActiveClick = (params: any) => {
    if (activeChecked) {
      activeConfirmationPopupState.setState(true);
    } else {
      inActiveConfirmationPopupState.setState(true);
    }
    let staffId = params.id.toString();
    setActiveStaffId(staffId);
    let staffName = params.row.name.toString();
    confirmationPopupTitleState.setState(staffName);
    setMenuState({ anchorEl: null, rowId: null });
  };

  const handleActiveStaff = () => {
    props.handleActiveStafft(activeStaffId);
  };

  const handleInActiveStaff = () => {
    props.handleInActiveStafft(activeStaffId);
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, rowId: null });
  };

  const handleCloseClick = () => {
    activeConfirmationPopupState.setState(false);
  };

  const handleInactivePopupClick = () => {
    inActiveConfirmationPopupState.setState(false);
  };

  const getRoleName = (roleIds: number[]) => {
    if (!!!roleIds || roleIds.length == 0) {
      return "";
    }
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  const getStaffTypeName = (
    staffTypeId: number,
    agencyDetails: AgencyDetail | null
  ) => {
    if (!!!staffTypeId) {
      return "";
    }
    let staffTypeName = "";
    staffTypeListState.getState().staffTypeList.forEach((element) => {
      if (staffTypeId == element.id) {
        staffTypeName = !!staffTypeName ? staffTypeName + ", " : "";
        staffTypeName = staffTypeName + element.name;
      }
    });

    return staffTypeName;
  };

  const handleAddStaff = () => {
    props.handleAddStaff();
  };

  return (
    <>
      {(staffDetailList == null || staffDetailList.length <= 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            width: "100%",
            marginTop: "6%",
          }}
        >
          <SearchIcon fontSize="large" sx={{ color: "rgba(61, 153, 245, 0.5)" }} />
          <Typography32_600 text="No active talent" color="rgba(61, 153, 245, 0.5)" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  cursor: "pointer",
                }}
                onClick={handleAddStaff}
              >
                <AddIcon fontSize="medium" sx={{ color: "#0D80F2" }} />
                <Typography16_600 text="Add talent manually" color="#0D80F2" />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Add talent by manually, providing mandatory "
                  color="#8F98A3"
                />
                <Typography16_400 text="details." color="#8F98A3" />
              </Box>
            </Box>
            <Divider
              sx={{
                borderColor: "#CFE5FC",
              }}
              orientation="vertical"
              flexItem
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  cursor: "pointer",
                }}
              >
                <AddIcon fontSize="medium" sx={{ color: "#0D80F2" }} />
                <Typography16_600
                  text="Add talent with bulk upload"
                  color="#0D80F2"
                />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Add talent using bulk upload feature and edit the "
                  color="#8F98A3"
                />
                <Typography16_400 text="letter as needed." color="#8F98A3" />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {staffDetailList != null && staffDetailList.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "6px",
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "8px",
                width: "100%",
                height: "90%",
              }}
            >
              <DataGrid
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .theme--header": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #E3E5E8",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F5FAFE",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    background: "#F9FAFA",
                  },
                  "& .MuiDataGrid-columnHeaderTitle" : {
                    fontWeight:"600",
                    color:"rgba(115, 127, 140, 1)"
                  },
                  "& .MuiCheckbox-root": {
                    color: "#ABB2BA !important",
                  },
                  "& .Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  "& .MuiDataGrid-scrollbarFiller": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-root .MuiDataGrid-filler": {
                    backgroundColor: "#F9FAFA",
                  },
                  border: "1px solid #E3E5E8",
                }}
                key={rowData.id}
                onRowClick={handleEditStaffClick}
                rows={rows}
                columns={[
                  {
                    field: "name",
                    headerName: "Talent name",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: true,
                    renderCell: (params: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 4px",
                            height: "32px",
                            justifyContent: "flex-start",
                            cursor:"pointer"
                          }}
                        >
                          <Typography14_600 text={params.row.name} />
                        </Box>
                      );
                    },
                  },
                  {
                    field: "email",
                    headerName: "Email",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: "phone",
                    headerName: "Phone",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                  },
                  {
                    field: "role",
                    headerName: "Role",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) => `${getRoleName(row.role)} `,
                  },
                  {
                    field: "staffType",
                    headerName: "Talent type",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    renderCell: (params: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 4px",
                            gap: "10px",
                          }}
                        >
                          {params.row.staffType == 103 && (
                            <>
                              <Avatar
                              src={params.row.agencyDetail?.logo_url} 
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  background: "#FFFFFF",
                                  color: "#ABB2BA",
                                  border: "1px solid #E3E5E8",
                                  borderRadius: "8px",
                                  fontSize: "12px",
                                }}
                                variant="square"
                              />
                              <Typography14_400
                                text={params.row.agencyDetail?.name}
                                color="inherit !important"
                              />
                            </>
                          )}
                          {params.row.staffType == 104 && (
                            <>
                              <Avatar
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  background: "#FFFFFF",
                                  color: "#ABB2BA",
                                  border: "1px solid #E3E5E8",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                }}
                                variant="square"
                                src={params.row.agencyDetail?.logo_url}  
                              >                                                            
                              </Avatar>

                              <Typography14_400
                                text="Contractor"
                                color="inherit !important"
                              />
                            </>
                          )}
                          {params.row.staffType == 102 && (
                            <>
                              <Avatar
                                sx={{
                                  width: "24px",
                                  height: "24px",
                                  background: "#FFFFFF",
                                  color: "#ABB2BA",
                                  border: "1px solid #E3E5E8",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                }}
                                variant="square"
                              >
                                <OrganizationIcon
                                  sx={{
                                    color: "#17191C",
                                    height: "19px",
                                    width: "19px",
                                  }}
                                />
                              </Avatar>

                              <Typography14_400
                                text="Internal employee"
                                color="inherit !important"
                              />
                            </>
                          )}
                        </Box>
                      );
                    },
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    renderCell: (params: any) => {
                      return (
                        <Chip
                          label={params.row.status ? "Active" : "Inactive"}
                          sx={{
                            height: "22px",
                            background: params.row.status
                              ? "#F7FDF8"
                              : "#ffece5",
                            border: "1px solid #EBFAEE",
                            borderRadius: "4px",
                            color: params.row.status ? "#29A347" : "#ff5722",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        />
                      );
                    },
                  },
                  {
                    field: "actions",
                    type: "actions",
                    headerClassName: "theme--header",
                    cellClassName: "actions",
                    align: "right",
                    width: 60,
                    renderCell: (params: any) => {
                      const isOpen = menuState.rowId === params.id;
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                          key={`action-${params.id}`}
                        >
                          <Box
                            sx={{ color: "#0D80F2" }}
                            onClick={(event) => handleClick(event, params)}
                          >
                            <IconButton
                              sx={{
                                background: "#FFFFFF",
                                color: "#0D80F2",
                                borderRadius: "10px !important",
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Box>
                          <Menu
                            anchorEl={menuState.anchorEl}
                            id="account-menu"
                            open={isOpen}
                            onClose={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                width: "228px",
                                borderRadius: "8px",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "bstaffk",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-1-${params.id}`}
                              onClick={() => {}}
                            >
                              <DeleteIcon sx={{ color: "#0D80F2" }} />{" "}
                              <Typography16_400 text="Delete" color="#031A30" />
                            </ListItemButton> */}
                            <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-2-${params.id}`}
                              onClick={() => handleActiveClick(params)}
                            >
                              <ActiveIcon sx={{ color: "#0D80F2" }} />
                              <Typography16_400 text="Active" color="#031A30" />
                              <Box sx={{ marginLeft: "35%" }}>
                                <IosSwitch checked={activeChecked} />
                              </Box>
                            </ListItemButton>
                          </Menu>
                        </Box>
                      );
                    },
                  },
                ]}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                checkboxSelection
              />
            </Box>
          </Box>
          <ActiveConfirmationPopup
            title={`Deactivate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to deactivate “${confirmationPopupTitleState.getState()}” talent?`}
            open={showActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleCloseClick}
              />
              <ContainedDeleteButton
                sx={{ height: "40px"}}
                text="Deactivate talent"
                handleClick={handleActiveStaff}
              />
            </Box>
          </ActiveConfirmationPopup>
          <InActiveConfirmationPopup
            title={`Activate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to activate “${confirmationPopupTitleState.getState()}” talent?`}
            open={showInActiveConfirmationPopup}
            handleClick={handleInactivePopupClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleInactivePopupClick}
              />
              <ContainedButton
                sx={{ height: "40px" }}
                text="Activate talent"
                handleClick={handleInActiveStaff}
              />
            </Box>
          </InActiveConfirmationPopup>
        </>
      )}
    </>
  );
};

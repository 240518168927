import Joi from "joi";
import {
  Decryption,
  EmptyGuid,
  emptyUnitDetail,
  Encryption,
} from "../../../libs/common-lib";
import { UnitDetail } from "../../../libs/model-lib";
import {
  updateUnit,
  createUnit,
  getSubDepartmentByOrgId,
  getSubDepartmentById,
} from "../../../libs/services-lib";
import {
  unitDetailState,
  loadingIconState,
  updateAlertState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { DetailSettingBox } from "../../organism";
import { UnitDetailForm } from "../../organism/location/unitDetailForm";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UnitDetailContext } from "../../../libs/context-lib/unitDetailContext";

const UnitDetailTemplate = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const { id } = useParams();
  const [unitDetail, setUnitDetail] = useState<UnitDetail>(emptyUnitDetail);
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (!!id) {
        if (Decryption(id) == EmptyGuid) {
          setUnitDetail(emptyUnitDetail);
        } else {
          loadingIconState.setState(true);
          let uDetail = await getSubDepartmentById(Decryption(id));
          setUnitDetail(uDetail);
          loadingIconState.setState(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const unitValidationSchema = Joi.object<UnitDetail>({
    id: Joi.string().allow(""),
    name: Joi.string().required().max(100).messages({
      "string.empty": `Sub-department name cannot be an empty field`,
      "string.max": `The maximum character limit should be 100`,
    }),
    department_id: Joi.string().required().messages({
      "string.empty": `Department cannot be an empty field`,
    }),
    is_active: Joi.boolean(),
  }).options({ abortEarly: false });

  const handleUnitSaveClick = async () => {
    const result = unitValidationSchema.validate(unitDetail);
    let errorMessage: any[] = [];
    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        errorMessage.push({ field: field, errorMessage: cc.message });
      });
      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);

    let uDetail = { ...unitDetail };

    if (!!uDetail.id && uDetail.id != EmptyGuid) {
      try {
        await updateUnit(uDetail);
      } catch (error) {}
    } else {
      uDetail.id = EmptyGuid;
      try {
        await createUnit(uDetail);
        setUnitDetail(emptyUnitDetail);
      } catch (error) {}
    }

    getSubDepartmentByOrgId(organizationDetailState.getState().id);
    loadingIconState.setState(false);
  };

  const handleUnitCancelClick = () => {
    navigate("/settings/locations-departments");
    navigate(`/settings/locations-departments/${Encryption("2")}`);
  };

  return (
    <DetailSettingBox
      navigationSelectedMenu="Location"
      playBoxTitle="Setting up locations and departments"
      playBoxDuration="1 min."
      pageTitle={
        !!id && Decryption(id) == EmptyGuid
          ? "Add sub-department"
          : "Update sub-department"
      }
      handleSaveClick={handleUnitSaveClick}
      handleCancelClick={handleUnitCancelClick}
    >
      <UnitDetailContext.Provider value={{ unitDetail, setUnitDetail }}>
        <UnitDetailForm errorMessage={errorMessage} />
      </UnitDetailContext.Provider>
    </DetailSettingBox>
  );
};

export default UnitDetailTemplate;

import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import {
  LocationDepartmentDetail,
  Shift,
  ShiftDetail,
  StaffDetail,
  StaffWorkingHours,
} from "../../../libs/model-lib";
import {
  shiftListState,
  staffTypeListState,
} from "../../../libs/state-management-lib";
import { Box } from "@mui/material";
import { CalendarAddShiftButtonBox } from "../../atom/box/calendarBoxes/calendarAddShiftButtonBox";
import { CalendarShiftBox } from "../../atom/box/calendarBoxes/calendarShiftBox";
import { EmptyGuid, emptyShift, Encryption } from "../../../libs/common-lib";
import { CalendarStaffDetailBox } from "../../atom/box/calendarBoxes/calendarStaffDetailBox";
import { calendarStaffTabValueState, staffDetailListState } from "../../../libs/state-management-lib/staffState";
import { CalendarOpenShiftsBox } from "../../atom/box/calendarBoxes/calendarOpenShiftsBox";
import { ContainedButton, Typography12_400, Typography12_600, Typography14_400 } from "../../atom";
import { useNavigate } from "react-router-dom";

interface ICalendarShiftsContainer {
  locationDepartmentDetail: LocationDepartmentDetail;
  allShifts: Shift[];
  staffDetailList: StaffDetail[];
  dateRange: Date[] | undefined,
  handleAddShiftDepartmentClick: (
    calendarDate: Date,
    departmentId: string
  ) => void;
  expanded: boolean;
  handleAddShiftOpen: (
    shiftOpen: boolean,
    locationDepartmentDetail: LocationDepartmentDetail,
    shiftDate: Date,
    staffDetail? : StaffDetail
  ) => void;
  handleEditShiftOpen: (
    shiftOpen: boolean,
    shiftDetail: Shift,
    locationDepartmentDetail: LocationDepartmentDetail
  ) => void;
}

export const CalendarShiftContainer = (props: ICalendarShiftsContainer) => {
  const [maxShifts, setMaxShifts] = useState<number>(0);
  const [calendarStaffTabValue, setCalendarStaffTabValue] = useState<number>(0);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const calendarStaffTabValueStateSub = calendarStaffTabValueState.subscribe(
      async (state) => {
        setCalendarStaffTabValue(state);
      }
    );

    return () => {
      calendarStaffTabValueStateSub();
    };
  }, []);

  useEffect(() => {
    let maxNumberOfShifts = 0;

    if (
      !!props.dateRange &&
      props.dateRange.length > 0 &&
      !!props.allShifts &&
      props.allShifts.length > 0
    ) {
      props.dateRange.forEach((cc) => {
        let departmentShiftsCurrentDate = shiftListState
          .getState()
          .shiftList.filter((ee) => {
            let startDate = new Date(ee.start_date);
            startDate.setHours(0, 0, 0, 0);
            return (
              ee.department_id ==
                props.locationDepartmentDetail.department_id &&
              cc.toDateString() == startDate.toDateString() && (ee.shift_status == 2 || ee.shift_status == 4 || ee.shift_status == 5)
            );
          });

        if (departmentShiftsCurrentDate.length > maxNumberOfShifts) {
          maxNumberOfShifts = departmentShiftsCurrentDate.length;
        }
      });

      setMaxShifts(maxNumberOfShifts);
    }
  }, [props.dateRange, props.allShifts]);

  const getShiftList = (date: Date, staffDetail: StaffDetail) => {
    if (!!props.allShifts && props.allShifts.length > 0) {
      let departmentShifts = props.allShifts.filter(
        (dd) =>
          dd.department_id == props.locationDepartmentDetail.department_id &&
          !!dd.assigned_staff_detail &&
          dd.assigned_staff_detail.id == staffDetail.id
      );

      if (!!departmentShifts && departmentShifts.length > 0) {
        let currentDateShifts = departmentShifts.filter((ee) => {
          let startDate = new Date(ee.start_date);
          startDate.setHours(0, 0, 0, 0);
          return date.toDateString() == startDate.toDateString();
        });

        return currentDateShifts;
      }

      return [];
    }

    return [];
  };

  const getOpenShiftList = (date: Date, calendarStaffTabValue: number) => {
    if (!!props.allShifts && props.allShifts.length > 0) {
      let departmentShifts = props.allShifts.filter(
        (dd) =>
          dd.department_id == props.locationDepartmentDetail.department_id &&
          (dd.shift_status == 2 || dd.shift_status == 4 || dd.shift_status == 5)
      );

      if (!!departmentShifts && departmentShifts.length > 0) {
        let currentDateShifts = departmentShifts.filter((ee) => {
          let startDate = new Date(ee.start_date);
          startDate.setHours(0, 0, 0, 0);
          let matched = false;

          if(!!ee.staff_type_ids && ee.staff_type_ids.length > 0) {
            if (calendarStaffTabValue > 0) {
              if (calendarStaffTabValue == 1) {
                if (
                  ee.staff_type_ids.find(
                    (cc) =>
                      cc ==
                      staffTypeListState
                        .getState()
                        .staffTypeList.filter((cc) => cc.name == "Internal")[0]
                        .id
                  )
                ) {
                  matched = true;
                }
              }

              if (calendarStaffTabValue == 2) {
                if (
                  ee.staff_type_ids.find(
                    (cc) =>
                      cc ==
                      staffTypeListState
                        .getState()
                        .staffTypeList.filter(
                          (cc) => cc.name == "External: Agency Talent"
                        )[0].id
                  ) ||
                  ee.staff_type_ids.find(
                    (cc) =>
                      cc ==
                      staffTypeListState
                        .getState()
                        .staffTypeList.filter(
                          (cc) => cc.name == "External: Non-Agency Talent"
                        )[0].id
                  )
                ) {
                  matched = true;
                }
              }
            }
            else {
              matched = true;
            }
          }
          else {
            matched = true;
          }

          return matched && date.toDateString() == startDate.toDateString();
        });

        if (currentDateShifts.length < maxShifts) {
          for (let i = currentDateShifts.length; i < maxShifts; i++) {
            currentDateShifts.push(emptyShift);
          }
        }

        return currentDateShifts;
      }

      return [];
    }

    return [];
  };

  const getStaffDetailsByDeptId = (calendarStaffTabValue: number): StaffDetail[] => {
    if (!!!props.staffDetailList || props.staffDetailList.length <= 0) {
      return [];
    }

    if (!!!props.allShifts || props.allShifts.length <= 0) {
      return [];
    }

    let departStaffList: StaffDetail[] = [];

    props.staffDetailList.forEach((staff: StaffDetail) => {
      let shiftAssociated: Shift[] = [];

      if(calendarStaffTabValue > 0) {
        if (
          calendarStaffTabValue == 1 &&
          staff.staff_type !=
            staffTypeListState
              .getState()
              .staffTypeList.filter((cc) => cc.name == "Internal")[0].id
        ) {
          return;
        }

        if (calendarStaffTabValue == 2 && (staff.staff_type !=  staffTypeListState
          .getState()
          .staffTypeList.filter((cc) => cc.name == "External: Agency Talent")[0].id && staff.staff_type !=  staffTypeListState
          .getState()
          .staffTypeList.filter((cc) => cc.name == "External: Non-Agency Talent")[0].id)) {
            return;
          }
      }

      props.dateRange?.forEach((date: Date) => {
        let dateFilteredAssociatedShifts = props.allShifts.filter((ee) => {
          let startDate = new Date(ee.start_date);
          startDate.setHours(0, 0, 0, 0);
          return (
            date.toDateString() == startDate.toDateString() &&
            !!ee.assigned_staff_detail &&
            ee.assigned_staff_detail.id == staff.id &&
            ee.department_id == props.locationDepartmentDetail.department_id
          );
        });

        if (
          !!dateFilteredAssociatedShifts &&
          dateFilteredAssociatedShifts.length > 0
        ) {
          shiftAssociated = shiftAssociated.concat(
            dateFilteredAssociatedShifts
          );
        }
      });

      if (!!!shiftAssociated || shiftAssociated.length <= 0) {
        return;
      }

      if (
        !!!staff.staffLocationMappings ||
        staff.staffLocationMappings.length <= 0
      ) {
        staff.staffWorkingHours = shiftAssociated.filter(cc => cc.shift_status == 1).reduce(
          (n, { shift_duration }) => n + shift_duration,
          0
        );
        departStaffList.push(staff);
        return;
      }

      if (
        staff.staffLocationMappings.find(
          (cc) =>
            cc.location_id == props.locationDepartmentDetail.location_id &&
            cc.department_id == props.locationDepartmentDetail.department_id
        )
      ) {
        staff.staffWorkingHours = shiftAssociated.filter(cc => cc.shift_status == 1).reduce(
          (n, { shift_duration }) => n + shift_duration,
          0
        );
        departStaffList.push(staff);
        return;
      }

      if (
        staff.staffLocationMappings.find(
          (cc) =>
            cc.location_id == props.locationDepartmentDetail.location_id &&
            !!!cc.department_id
        )
      ) {
        staff.staffWorkingHours = shiftAssociated.filter(cc => cc.shift_status == 1).reduce(
          (n, { shift_duration }) => n + shift_duration,
          0
        );
        departStaffList.push(staff);
        return;
      }
    });

    return departStaffList;
  };

  const getNumberOfNonAssignedShifts = (calendarStaffTabValue: number) => {
    if(!!!props.allShifts || props.allShifts.length <= 0) {
      return 0;
    }

    let numberOfAssignedShift: Shift[] = [];

    props.dateRange?.forEach((date: Date) => {
      let dateFilteredOpenShifts = props.allShifts.filter((ee) => {
        let startDate = new Date(ee.start_date);
        startDate.setHours(0, 0, 0, 0);
        let matched = false;

        if (!!ee.staff_type_ids && ee.staff_type_ids.length > 0) {
          if (calendarStaffTabValue > 0) {
            if (calendarStaffTabValue == 1) {
              if (
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter((cc) => cc.name == "Internal")[0].id
                )
              ) {
                matched = true;
              }
            }

            if (calendarStaffTabValue == 2) {
              if (
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter(
                        (cc) => cc.name == "External: Agency Talent"
                      )[0].id
                ) ||
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter(
                        (cc) => cc.name == "External: Non-Agency Talent"
                      )[0].id
                )
              ) {
                matched = true;
              }
            }
          } else {
            matched = true;
          }
        } else {
          matched = true;
        }

        return (
          matched &&
          date.toDateString() == startDate.toDateString() &&
          ee.department_id == props.locationDepartmentDetail.department_id &&
          (ee.shift_status == 2 || ee.shift_status == 4 || ee.shift_status == 5)
        );
      });

      if(!!dateFilteredOpenShifts && dateFilteredOpenShifts.length > 0) {
        numberOfAssignedShift =  numberOfAssignedShift.concat(dateFilteredOpenShifts);
      }
    });

    return numberOfAssignedShift.length;
  }

  const getNumberOfOpenShifts = (calendarStaffTabValue: number) => {
    if(!!!props.allShifts || props.allShifts.length <= 0) {
      return 0;
    }

    let numberOfShiftsOpen: Shift[] = [];

    props.dateRange?.forEach((date: Date) => {
      let dateFilteredOpenShifts = props.allShifts.filter((ee) => {
        let startDate = new Date(ee.start_date);
        startDate.setHours(0, 0, 0, 0);
        let matched = false;

        if (!!ee.staff_type_ids && ee.staff_type_ids.length > 0) {
          if (calendarStaffTabValue > 0) {
            if (calendarStaffTabValue == 1) {
              if (
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter((cc) => cc.name == "Internal")[0].id
                )
              ) {
                matched = true;
              }
            }

            if (calendarStaffTabValue == 2) {
              if (
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter(
                        (cc) => cc.name == "External: Agency Talent"
                      )[0].id
                ) ||
                ee.staff_type_ids.find(
                  (cc) =>
                    cc ==
                    staffTypeListState
                      .getState()
                      .staffTypeList.filter(
                        (cc) => cc.name == "External: Non-Agency Talent"
                      )[0].id
                )
              ) {
                matched = true;
              }
            }
          } else {
            matched = true;
          }
        } else {
          matched = true;
        }

        return (
          matched &&
          date.toDateString() == startDate.toDateString() &&
          ee.department_id == props.locationDepartmentDetail.department_id &&
          ee.shift_status == 2
        );
      });

      if(!!dateFilteredOpenShifts && dateFilteredOpenShifts.length > 0) {
        numberOfShiftsOpen =  numberOfShiftsOpen.concat(dateFilteredOpenShifts);
      }
    });

    return numberOfShiftsOpen.length;
  }

  const handleAddStaffClick = () => {
    navigate(`/settings/talent/${Encryption(EmptyGuid)}`);
  };

  const getStaffListByTabValue = (calendarStaffTabValue: number) => {
    if (!!!props.staffDetailList || props.staffDetailList.length <= 0) {
      return true;
    }

    if (calendarStaffTabValue == 0) {
      return false;
    }

    if (calendarStaffTabValue == 1) {
      if (
        props.staffDetailList.find(
          (cc) =>
            cc.staff_type ==
            staffTypeListState
              .getState()
              .staffTypeList.filter((cc) => cc.name == "Internal")[0].id
        )
      ) {
        return false;
      }
    }

    if (calendarStaffTabValue == 2) {
      if (
        props.staffDetailList.find(
          (cc) =>
            cc.staff_type ==
            staffTypeListState
              .getState()
              .staffTypeList.filter(
                (cc) => cc.name == "External: Agency Talent"
              )[0].id
        ) ||
        props.staffDetailList.find(
          (cc) =>
            cc.staff_type ==
            staffTypeListState
              .getState()
              .staffTypeList.filter(
                (cc) => cc.name == "External: Non-Agency Talent"
              )[0].id
        )
      ) {
        return false;
      }
    }

    return true;
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: props.expanded ? "flex" : "none", //"flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {getStaffDetailsByDeptId(calendarStaffTabValue).map(
          (staffDetail: StaffDetail) => (
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "row" }}
              key={staffDetail.id}
            >
              <Box
                sx={{
                  width: "calc(16% - 8px)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  padding: "8px",
                  border: "1px solid #E7F2FE",
                }}
              >
                <CalendarStaffDetailBox
                  key={`${staffDetail.id}_${props.locationDepartmentDetail.department_id}`}
                  staffDetail={staffDetail}
                />
              </Box>
              <Box
                sx={{
                  width: "84%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {!!props.dateRange &&
                  props.dateRange.length > 0 &&
                  props.dateRange.map((cc: Date, index: number) => (
                    <Box
                      sx={{
                        width: "calc(100% / 7)",
                        fontSize: "12px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#CFE5FC",
                      }}
                      key={index}
                    >
                      {getShiftList(cc, staffDetail).length > 0 ? (
                        getShiftList(cc, staffDetail).map(
                          (ff: Shift, index2: number) => (
                            <Fragment key={index2}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  height: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "8px 0px 8px 0px",
                                  border: "1px solid #E7F2FE",
                                  minHeight: "96px",
                                }}
                              >
                                <CalendarShiftBox
                                  shiftDetails={ff}
                                  handleShiftClick={(shiftDetail: Shift) => {
                                    props.handleEditShiftOpen(
                                      true,
                                      shiftDetail,
                                      props.locationDepartmentDetail
                                    );
                                  }}
                                />
                              </Box>
                            </Fragment>
                          )
                        )
                      ) : (
                        <Fragment>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "8px 0px 8px 0px",
                              border: "1px solid #E7F2FE",
                              minHeight: "96px",
                            }}
                          >
                            <CalendarAddShiftButtonBox
                              locationDepartmentDetail={
                                props.locationDepartmentDetail
                              }
                              handleAddShiftOpen={props.handleAddShiftOpen}
                              shiftDate={cc}
                              staffDetail={staffDetail}
                            />
                          </Box>
                        </Fragment>
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>
          )
        )}

        {/* {getStaffListByTabValue(calendarStaffTabValue) && (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                width: "16%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #E7F2FE",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "40px 8px 8px 8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "0px 16px 0px 16px",
                  }}
                >
                  <Typography14_400
                    text="There is no talent added yet. Add them first, so you can assign the shift."
                    color="#737F8C"
                  />
                  <ContainedButton
                    sx={{
                      height: "32px",
                      padding: "4px 12px 4px 12px",
                    }}
                    text={"Add talent"}
                    handleClick={handleAddStaffClick}
                  />
                </Box>
              </Box>
            </Box>
            {!!props.dateRange &&
              props.dateRange.length > 0 &&
              props.dateRange.map((cc: Date, index: number) => (
                <Box
                  sx={{
                    width: "calc(84% / 7)",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#CFE5FC",
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "8px 0px 8px 0px",
                      border: "1px solid #E7F2FE",
                      minHeight: "96px",
                    }}
                  >
                    <CalendarAddShiftButtonBox
                      locationDepartmentDetail={props.locationDepartmentDetail}
                      handleAddShiftOpen={props.handleAddShiftOpen}
                      shiftDate={cc}
                    />
                  </Box>
                </Box>
              ))}
          </Box>
        )} */}

        {getNumberOfNonAssignedShifts(calendarStaffTabValue) > 0 && (
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                width: "calc(16% - 8px)",
                display: "flex",
                flexDirection: "column",
                padding: "8px",
                border: "1px solid #E7F2FE",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography12_600 text="Open shifts" color="#737F8C" />
                  <Typography12_400
                    text={String(getNumberOfOpenShifts(calendarStaffTabValue))}
                    color="#737F8C"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "84%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!!props.dateRange &&
                props.dateRange.length > 0 &&
                props.dateRange.map((cc: Date, index: number) => (
                  <Box
                    sx={{
                      width: "calc(100% / 7)",
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#CFE5FC",
                    }}
                    key={index}
                  >
                    {getOpenShiftList(cc, calendarStaffTabValue).map(
                      (ff: Shift, index2: number) => (
                        <Fragment key={index2}>
                          {(!!!ff.id || ff.id == EmptyGuid) && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "8px 0px 8px 0px",
                                border: "1px solid #E7F2FE",
                                minHeight: "96px",
                              }}
                            >
                              <CalendarAddShiftButtonBox
                                locationDepartmentDetail={
                                  props.locationDepartmentDetail
                                }
                                handleAddShiftOpen={props.handleAddShiftOpen}
                                shiftDate={cc}
                              />
                            </Box>
                          )}
                          {!!ff.id && ff.id != EmptyGuid && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "8px 0px 8px 0px",
                                border: "1px solid #E7F2FE",
                                minHeight: "96px",
                              }}
                            >
                              <CalendarShiftBox
                                shiftDetails={ff}
                                handleShiftClick={(shiftDetail: Shift) => {
                                  props.handleEditShiftOpen(
                                    true,
                                    shiftDetail,
                                    props.locationDepartmentDetail
                                  );
                                }}
                              />
                            </Box>
                          )}
                        </Fragment>
                      )
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

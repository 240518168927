import {
  axiosInstance,
  handleApiErrorAlert,
  handleApiSuccessAlert,
} from "../common-lib";
import {
  Shift,
  SearchShift,
  ShiftAssignment,
  ShiftLog,
  ShiftSeries,
  ShareShift,
  ShareOpenShifts,
  ShiftRotation,
} from "../model-lib";
import {
  apiAlertState,
  customTagListState,
  errorAlertState,
  errorMessageState,
  openShiftListState,
  organizationDetailState,
  shiftDetailState,
  shiftListState,
} from "../state-management-lib";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/Shift`;
export const createShift = async (shift: Shift) => {
  try {
    shift.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/CreateShift`,
      shift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift created successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const getShiftById = async (shiftId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetShiftById?shiftId=${shiftId}&orgId=${
        organizationDetailState.getState().id
      }`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      shiftDetailState.setState(response.data.data);
    }
  } catch (error) {}
};

export const getAllShifts = async (searchShift: SearchShift) => {
  try {
    searchShift.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/GetShifts`,
      searchShift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      shiftListState.setState({
        shiftList: response.data.data,
      });

      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const updateShift = async (shift: Shift) => {
  try {
    shift.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateShift`,
      shift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift updated successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const getAssignmentByStaffId = async (staffId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetAssignmentByStaffId?staffId=${staffId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const createShiftAssignment = async (
  shiftAssignment: ShiftAssignment
) => {
  try {
    shiftAssignment.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/CreateShiftAssignment`,
      shiftAssignment
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift assignment done successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const updateShiftAssignment = async (
  shiftAssignment: ShiftAssignment
) => {
  try {
    shiftAssignment.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateShiftAssignment`,
      shiftAssignment
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift assignment updated successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const removeShiftAssignment = async (
  shiftAssignment: ShiftAssignment
) => {
  try {
    shiftAssignment.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/RemoveShiftAssignment`,
      shiftAssignment
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift assignment removed successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const cancelShift = async (shift: ShiftLog) => {
  try {
    shift.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/CancelShift`,
      shift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift cancelled successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const deleteShift = async (shift: Shift) => {
  try {
    shift.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/DeleteShift`,
      shift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift removed successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const createShiftSeries = async (shiftSeries: ShiftSeries) => {
  try {
    shiftSeries.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/CreateShiftSeries`,
      shiftSeries
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift series created successfully");
      return response.data.data;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const getOpenShifts = async (shareShift: ShareShift) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/GetOpenShifts`,
      shareShift
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      openShiftListState.setState({ openShiftList: response.data.data });
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const createShareShiftLogs = async (
  shareOpenShifts: ShareOpenShifts
) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateShareShiftLogs`,
      shareOpenShifts
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift shared successfully");
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const createRotationShift = async (shiftRotation: ShiftRotation) => {
  try {
    shiftRotation.orgId = organizationDetailState.getState().id;

    let response = await axiosInstance.post(
      `${organization_base_url}/CreateShiftRotation`,
      shiftRotation
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      handleApiSuccessAlert("Shift rotation created successfully");
      return shiftRotation;
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
  }
};

export const getCustomTagByOrgId = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetCutomtagByOrgId?orgId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode == 200) {
      customTagListState.setState({ customTagList: response.data.data });
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};
import { Box, Typography } from "@mui/material";
import {
  ActiveStatusAlert,
  ContainedButton,
  ErrorAlert,
  FilterIcon,
  OutlinedButton,
  OverFlowBox,
  PlayCircleIcon,
  SearchTextField,
  SettingBox,
  SuccessAlert,
  Typography12_400,
  Typography18_600,
  UpdateAlert,
} from "../../atom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import { RolesDashboard } from "./rolessDashboard";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { RolesDetailFoem } from "./rolesDetailFoem";
import {
  activeConfirmationPopupState,
  activeStatusAlertState,
  deleteConfirmationPopupState,
  errorAlertState,
  errorMessageState,
  inActiveConfirmationPopupState,
  loadingIconState,
  organizationDetailState,
  roleDetailState,
  roleListState,
  successAlertState,
  updateAlertState,
} from "../../../libs/state-management-lib";
import {
  createRole,
  getAllDepartmentsByLocationId,
  getAllDepartmentsByOrgId,
  getAllLocationsByOrgId,
  getDepartmentByOrganizationId,
  getOrganizationRoleById,
  getPrimaryQualificationList,
  getRoleByOrganizationId,
  getSubDepartmentByDepartmentId,
  getSubDepartmentByOrgId,
  getSubDepartmentList,
  updateRole,
} from "../../../libs/services-lib";
import { EmptyGuid, emptyRoleDetail, Encryption } from "../../../libs/common-lib";
import Joi from "joi";
import { OrganizationRole } from "../../../libs/model-lib";
import { useNavigate } from "react-router-dom";

export const RolesSetting = () => {
  const [roleList, setRolelList] = useState<any[]>([]);
  const [roleDetail, setRoleDetail] = useState<OrganizationRole>(
    roleDetailState.getState()
  );
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const roleDetailStateSub = roleDetailState.subscribe(async (state) => {
      setRoleDetail(state);
    });

    const roleDetailListStateSub = roleListState.subscribe(async (state) => {
      setRolelList(state.roleList);
    });

    return () => {
      roleDetailStateSub();
      roleDetailListStateSub();
    };
  }, []);

  useEffect(() => {
    refreshEvent();
  }, []);

  useEffect(() => {
    roleDetailState.setState(roleDetail);
  }, [roleDetail]);

  const refreshEvent = async () => {
    loadingIconState.setState(true);
    await Promise.all([
      getRoleByOrganizationId(organizationDetailState.getState().id),
      getPrimaryQualificationList(),
      getAllLocationsByOrgId(organizationDetailState.getState().id),
      getAllDepartmentsByOrgId(organizationDetailState.getState().id),
      getSubDepartmentList(),
      getSubDepartmentByOrgId(organizationDetailState.getState().id),
    ]);
    loadingIconState.setState(false);
  };

  const handleAddRolesClick = () => {
    navigate(`/settings/role/${Encryption(EmptyGuid)}`);
  };

  const handleRoleDashboardCallBack = async (roleId: string) => {
    navigate(`/settings/role/${Encryption(roleId)}`);
  };

  const handleActiveRole = async (roleId: string) => {
    loadingIconState.setState(true);
    await getOrganizationRoleById(roleId);
    let roleDetail = roleDetailState.getState();
    roleDetail.is_active = false;
    try {
      await updateRole(roleDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    activeConfirmationPopupState.setState(false);
  };

  const handleInActiveRole = async (roleId: string) => {
    loadingIconState.setState(true);
    await getOrganizationRoleById(roleId);
    let roleDetail = roleDetailState.getState();
    roleDetail.is_active = true;
    try {
      await updateRole(roleDetail);
      refreshEvent();
    } catch (error) {}    
    loadingIconState.setState(false);
    inActiveConfirmationPopupState.setState(false);
  };

  const handleDleteRole = async (roleId: string) => {
    loadingIconState.setState(true);
    await getOrganizationRoleById(roleId);
    let roleDetail = roleDetailState.getState();
    roleDetail.is_active = false;
    try {
      await updateRole(roleDetail);
      refreshEvent();
    } catch (error) {}
    loadingIconState.setState(false);
    deleteConfirmationPopupState.setState(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "38%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "16px 24px 16px 24px",
            gap: "10px",
            borderRadius: "8px",
            backgroundColor: "#FEF8F5",
            marginTop: "20px",
            marginLeft: "20px",
          }}
        >
          <PlayCircleIcon
            sx={{
              color: "#F7906E",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                alignItems: "center",
                color: "#F7906E",
              }}
            >
              Setting up roles
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "12px",
                alignItems: "center",
                color: "#F7906E",
                lineHeight: "29px",
                 paddingLeft:"5px"
              }}
            >
              1 min.
            </Typography>
          </Box>
          <CloseOutlinedIcon
            sx={{
              color: "#F7906E",
              top: "5.2px",
              left: "5.2px",
              alignItems: "flex-end",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "1px solid #CFE5FC",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <Typography18_600 text="Roles" color="#17191C" />
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SearchTextField
              placeholder={"Search"}
              value={""}
              handleChange={(e: any) => {}}
            />
            <FilterIcon
              sx={{
                // color: "#0D80F2",                
                // cursor: "pointer",
                marginTop: "1%",
                paddingLeft: "2%",
                color:"#ABB2BA"
              }}
            />

            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "8px 16px",
                gap: "8px",
                borderRadius: "8px",
              }}
            >
              <ContainedButton
                text="Add roles"
                startIcon={
                  <AddIcon
                    sx={{ color: "#FFFFFF", width: "24px", height: "24px" }}
                  />
                }
                sx={{ height: "40px" }}
                handleClick={handleAddRolesClick}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <OverFlowBox
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "24px 18px 34px 10px",
          gap: "10px",
          width: "calc(100% - 36px)",
          height: "calc(100% - 58px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "10px",
            gap: "10px",
            width: "calc(100% - 20px)",
            height: "calc(100% - 20px)",
          }}
        >
          <RolesDashboard
            handleRoleDashboardCallBack={handleRoleDashboardCallBack}
            handleActiveRole={handleActiveRole}
            handleInActiveRole={handleInActiveRole}
            handleDleteRole={handleDleteRole}
            handleAddRolesClick={handleAddRolesClick}
          />
        </Box>
      </OverFlowBox>
    </>
  );
};

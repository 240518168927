import { Box } from "@mui/material";
import { OverFlowBox } from "../../atom";
import { SettingNavigation } from "../../molecule";
import { StaffSetting } from "../../organism";

const StaffingTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SettingNavigation selectedMenu="Talents" />
      <OverFlowBox
        sx={{
          width: "85%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <StaffSetting />
      </OverFlowBox>
    </Box>
  );
};

export default StaffingTemplate;

import CryptoJS from 'crypto-js';
import { apiAlertState } from '../state-management-lib';

export const getErrorDetail = (field: string, errorMessage: any[]) => {
  if (!!errorMessage && errorMessage.length > 0) {
    let fieldError = errorMessage.filter((cc) => cc.field == field);
    if (!!fieldError && fieldError.length > 0) {
      return fieldError[0].errorMessage;
    }
  }

  return "";
};

export const getDateRange = (startDate: Date, endDate: Date, steps: number) => {
  const dateArray = [];

  while (startDate <= endDate) {
    dateArray.push(new Date(startDate));
    startDate = new Date(startDate.setDate(startDate.getDate() + steps));
  }

  return dateArray;
};

export const convertMinutestoHour = (minute: number) => {
  let num = minute;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);

  if (rminutes > 0 && rhours > 0) {
    return rhours + " hrs " + rminutes + " mins";
  }

  if (rminutes > 0) {
    return rminutes + " mins";
  }

  return rhours + " hrs";
}

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.split(" ").length > 1
    ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    : `${name.split(" ")[0][0]}`,
  };
}


const SECRET = 'p47@0]@O68/-';

export const Encryption = (plainText: string) => {
  if (!!plainText) {
    let b64 = CryptoJS.AES.encrypt(plainText.toString(), SECRET).toString();
    let e64 = CryptoJS.enc.Base64.parse(b64);
    let eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }
  return '';
};

export const Decryption = (cipherText: string) => {
  if (!!cipherText) {
    let reb64 = CryptoJS.enc.Hex.parse(cipherText);
    let bytes = reb64.toString(CryptoJS.enc.Base64);
    let decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
    let plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  }
  return '';
};

export const handleApiSuccessAlert = (successMessage: string) => {
  apiAlertState.setState({
    alertMessage: successMessage,
    serverity: "success",
    showAlert: true,
  });
}

export const handleApiErrorAlert = (error: any) => {
  let message = "Please contact support team";

  if(!!error.response && !!error.response.data) {
    if (error.response.data.statusCode == 400) {
      message =
        !!error.response.data.errors && error.response.data.errors.length > 0
          ? !!error.response.data.errors[0].message
            ? error.response.data.errors[0].message
            : "Please contact support team"
          : "Please contact support team";
    }
    else{
      message = error.response.data;
    }
  }

  
  
  apiAlertState.setState({
    alertMessage: message,
    serverity: "error",
    showAlert: true,
  });
}

export const handleValidationErrorAlert = (errorMessage: string) => {
  apiAlertState.setState({
    alertMessage: errorMessage,
    serverity: "error",
    showAlert: true,
  });
}